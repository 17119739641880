import { IconButton, Link, Text, VStack } from '@chakra-ui/react'

export const SocialsButton = ({
  link,
  linkIcon,
  linkText,
  ariaLabel,
  onClick,
}: {
  link: string
  linkIcon: JSX.Element
  linkText: string
  ariaLabel: string
  onClick: () => void
}): JSX.Element => {
  return (
    <Link
      href={link}
      isExternal
      textDecoration={'none'}
      _hover={{ textDecoration: 'none' }}
    >
      <VStack spacing={1}>
        <IconButton
          aria-label={ariaLabel}
          icon={linkIcon}
          onClick={onClick}
          colorScheme="grey"
          variant="ghost"
          borderRadius={'3xl'}
        />
        <Text textStyle="caption-3">{linkText}</Text>
      </VStack>
    </Link>
  )
}
