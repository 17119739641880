import { Button, Flex, Image, Link, Stack, Text } from '@chakra-ui/react'
import { BiRightArrowAlt } from 'react-icons/bi'

import OgpSuiteSvg from '~/assets/landing/OgpSuite.svg'

export const AllOgpProductsSection = () => {
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      align="left"
      spacing={{ base: '1.5rem', md: '3.125rem' }}
    >
      <Flex flexDir="column" flex={1}>
        <Text
          textStyle={{
            base: 'responsive-heading.heavy',
            md: 'responsive-heading.heavy-480',
            lg: 'responsive-heading.heavy-600',
          }}
          pb="2rem"
        >
          Tools built by public officers, for public officers
        </Text>
        <Text textStyle="subhead-1">
          LetterSG is part of the Open Government Products Suite. As a public
          officer, you can use a range of our productivity and collaboration
          tools in combination, to make your work easier and more efficient.
        </Text>
        <Link href={'https://www.open.gov.sg/products/overview'} isExternal>
          <Button variant="link" mt="2.5rem" rightIcon={<BiRightArrowAlt />}>
            Full list of OGP products
          </Button>
        </Link>
      </Flex>
      <Image src={OgpSuiteSvg} />
    </Stack>
  )
}
