import {
  Badge,
  Box,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

import LetterConfirmation from '~/assets/LetterConfirmation.svg'

interface SampleLetterModalProps {
  isOpen: boolean
  onClose: () => void
}

export const SampleLetterModal = ({
  isOpen,
  onClose,
}: SampleLetterModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent overflow="hidden" minW="680px">
        <ModalCloseButton onClick={onClose} />
        <Image src={LetterConfirmation} ml="auto" mr="auto" />
        <Box py="1.5rem" px="32px">
          <VStack spacing="1.5rem" alignItems="flex-start">
            <Badge variant="subtle" borderRadius={'xl'} width={'fit-content'}>
              For Demo Only
            </Badge>
            <Text textStyle="h4">
              Try LetterSG by sending yourself this sample letter
            </Text>
            <Text textStyle="body-1">
              This demo is for you to see how LetterSG works. Follow the
              instructions, and send yourself the sample letter.{' '}
              <b>Please do not send this to a member of the public.</b>
            </Text>
          </VStack>
        </Box>
        <ModalFooter px="32px" pt="1rem" pb="2rem">
          <Button onClick={onClose}>Continue</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
