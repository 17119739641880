import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Infobox } from '@opengovsg/design-system-react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import Note from '~/assets/Note.svg'
import { routes } from '~constants/routes'
import { useCreateTemplateMutation } from '~features/create/hooks/create.hooks'
import { useToast } from '~hooks/useToast'
import { ACCEPTED_TEMPLATE_NAME_REGEX } from '~shared/constants/regex'
import { GetTemplateDto } from '~shared/dtos/templates.dto'

export enum SOURCE_TYPE {
  CLONE_EDITOR = 'CLONE_EDITOR',
  CLONE_PREVIEW = 'CLONE_PREVIEW',
  CREATE = 'CREATE',
}

interface CreateTemplateModalProps {
  isOpen: boolean
  onClose: () => void
  originalTemplateName?: string
  templateHtml: string
  source: SOURCE_TYPE
  defaultTemplateName: string
}

interface FormData {
  templateName: string
  shareAccess: boolean
}

export const CreateTemplateModal = ({
  isOpen,
  onClose,
  originalTemplateName,
  templateHtml,
  defaultTemplateName,
  source,
}: CreateTemplateModalProps) => {
  const navigate = useNavigate()
  const toast = useToast()

  const { mutateAsync, isLoading } = useCreateTemplateMutation({
    onSuccess: (res: GetTemplateDto) => {
      toast({
        title:
          source === SOURCE_TYPE.CREATE
            ? `New template ${templateName} created`
            : 'Duplicated template successfully!',
        status: 'success',
      })
      switch (source) {
        case SOURCE_TYPE.CLONE_PREVIEW:
          navigate(
            `/${routes.admin.index}/${routes.admin.templates.index}/edit/${res.id}`,
            {
              state: { template: res, isCloned: true },
            },
          )
          break
        case SOURCE_TYPE.CREATE:
        case SOURCE_TYPE.CLONE_EDITOR:
          navigate(`/${routes.admin.index}/${routes.admin.templates.index}`)
          break
        default:
        // do nothing
      }
    },
  })

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<FormData>({ mode: 'onBlur' })
  const templateName = watch('templateName')

  const onSubmit = async (data: FormData): Promise<void> => {
    await mutateAsync({
      html: templateHtml,
      name: data.templateName.trim(),
    })
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={(event) => void handleSubmit(onSubmit)(event)}>
        <ModalContent>
          <ModalCloseButton onClick={onClose} />
          <Image alignSelf="center" src={Note} borderTopRadius={'8px'} />

          <ModalHeader>
            {source === SOURCE_TYPE.CREATE
              ? 'Give your template a name'
              : 'Duplicate template'}
          </ModalHeader>
          <ModalBody>
            <VStack spacing="2px" pb="15px" align={'left'}>
              <Infobox variant="warning">
                <Text>
                  Write an <b> appropriate title </b> for your template; it will
                  be <b>shown to citizens</b>.
                </Text>
              </Infobox>
            </VStack>

            <FormControl isRequired isInvalid={!!errors.templateName}>
              <VStack spacing="5px" align="left">
                <Input
                  {...register('templateName', {
                    validate: (value) => {
                      const trimmedValue = value.trim()
                      // Check if the value is empty after trimming
                      if (trimmedValue === '') {
                        return 'Title cannot be empty'
                      }
                      if (!ACCEPTED_TEMPLATE_NAME_REGEX.test(trimmedValue)) {
                        return 'Title should only contain letters, numbers, spaces, or characters: —@\'"’-()[]:#_&|,.?!/+'
                      }
                      return true
                    },
                  })}
                  defaultValue={defaultTemplateName}
                  id="template-name-modal"
                />
                {originalTemplateName &&
                  templateName?.trim() === originalTemplateName && (
                    <FormHelperText textColor={'blue.700'}>
                      Choosing a name identical to a previous template? Consider
                      a slight variation for better organization. If
                      intentional, proceed with the same name.
                    </FormHelperText>
                  )}
                <FormErrorMessage>
                  {errors.templateName && errors.templateName.message}
                </FormErrorMessage>
                {/* TODO Add logic for supporting access sharing with the same people */}
                {/* <Checkbox size="xs" {...register('shareAccess')}>
                  Share it with the same people
                </Checkbox> */}
              </VStack>
            </FormControl>
          </ModalBody>
          <Box px="32px" my="10px">
            {isLoading && <Progress size="md" isIndeterminate />}
          </Box>
          <ModalFooter>
            <Button
              variant="ghost"
              onClick={onClose}
              border={0}
              mr={3}
              colorScheme="blue"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              isLoading={isLoading}
              isDisabled={!!errors.templateName}
            >
              {source === SOURCE_TYPE.CREATE ? 'Save' : 'Make a copy'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}

export default CreateTemplateModal
