import { Box, Button, HStack, Text } from '@chakra-ui/react'

import { useIsDesktop } from '~hooks/useIsDesktop'

export const LandingPageToggleViewButton = ({
  isAgencySelected,
  setIsAgencySelected,
}: {
  isAgencySelected: boolean
  setIsAgencySelected: (isAgencySelected: boolean) => void
}) => {
  const isDesktop = useIsDesktop()
  return (
    <Box h={0} w={'full'}>
      <Box
        w={isDesktop ? '50%' : '90%'}
        p={2}
        bg={'interaction.main-subtle.default'}
        borderRadius={'8px'}
        position={'absolute'}
        transform={isDesktop ? 'translate(50%, -50%)' : 'translate(5%, -50%)'}
      >
        <Box w={'100%'} h={'full'} position={'relative'}>
          <Box
            h={'100%'}
            w={'50%'}
            bg={'base.content.brand'}
            borderRadius={'8px'}
            position={'absolute'}
            transform={isAgencySelected ? 'translateX(0)' : 'translateX(100%)'}
            transition={'transform 0.3s'}
            zIndex={0}
          />
          <HStack justify={'space-around'} zIndex={1} w={'full'}>
            <Button
              h={'full'}
              w={'50%'}
              bg={'transparent'}
              borderRadius={'8px'}
              borderWidth={0}
              variant={'unstyled'}
              onClick={() => setIsAgencySelected(true)}
              _hover={{ cursor: 'pointer' }}
              p={3}
            >
              <Text
                textStyle={'subhead-2'}
                transition={'color 0.3s'}
                color={
                  isAgencySelected ? 'standard.white' : 'base.content.brand'
                }
              >
                For government agencies
              </Text>
            </Button>
            <Button
              h={'full'}
              w={'50%'}
              bg={'transparent'}
              borderRadius={'8px'}
              variant={'unstyled'}
              borderWidth={0}
              onClick={() => setIsAgencySelected(false)}
              _hover={{ cursor: 'pointer' }}
              p={3}
            >
              <Text
                textStyle={'subhead-2'}
                transition={'color 0.3s'}
                color={
                  !isAgencySelected ? 'standard.white' : 'base.content.brand'
                }
              >
                For members of the public
              </Text>
            </Button>
          </HStack>
        </Box>
      </Box>
    </Box>
  )
}
