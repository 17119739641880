import {
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

import ApiInvalidateConfirmation from '~/assets/ApiInvalidateConfirmation.svg'

interface RegenerateApiKeyConfirmationModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}
export const RegenerateApiKeyConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
}: RegenerateApiKeyConfirmationModalProps): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton onClick={onClose} />
        <Image
          alignSelf="center"
          src={ApiInvalidateConfirmation}
          borderTopRadius={'8px'}
        />
        <VStack p="32px" alignItems={'left'}>
          <Text textStyle={'h4'} fontSize={'24px'}>
            Your existing API key will be invalidated
          </Text>
          <Text mb="1rem" textStyle={'subhead-1'}>
            Are you sure you want to regenerate your API key?
          </Text>
        </VStack>
        <ModalFooter>
          <Button
            variant="ghost"
            onClick={onClose}
            border={0}
            colorScheme="blue"
            mr={3}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onConfirm()
              onClose()
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
