export const desktopFormat = {
  heading: 'h1',
  secondaryHeading: 'h4',
  subHeading: 'subhead-1',
  body: 'body-1',
}

export const mobileFormat = {
  heading: 'h3',
  secondaryHeading: 'h6',
  subHeading: 'subhead-2',
  body: 'body-2',
}
