import { useQuery } from '@tanstack/react-query'

import { api } from '~/lib/api'

export const useGrowthbookApiKey = () => {
  const { data, isLoading } = useQuery<string | undefined>(
    ['growthbook-api-key'],
    () => api.get('/config/growthbookApi.clientKey').json<string>(),
    { retry: false },
  )
  return { growthbookApiKey: data, isLoadingGrowthbookApiKey: isLoading }
}
