import { Box, Button, Hide, HStack, Show, Text } from '@chakra-ui/react'
import React from 'react'
import { BiX } from 'react-icons/bi'

import CloseButton from './CloseButton'

interface ThankYouBoxProps {
  onClose: () => void
}

const ThankYouBox: React.FC<ThankYouBoxProps> = ({ onClose }) => {
  return (
    <HStack justify="space-between" width="100%">
      <Text textStyle={'h6'} ml="4">
        🎉 Thanks for your feedback!
      </Text>
      <CloseButton onClick={onClose} />
    </HStack>
  )
}

export default ThankYouBox
