import {
  Hide,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Show,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { Button, IconButton } from '@opengovsg/design-system-react'
import { BiChat, BiCopy, BiDownload, BiShareAlt } from 'react-icons/bi'
import { BsWhatsapp } from 'react-icons/bs'
import { FiMail } from 'react-icons/fi'

import { useToast } from '~hooks/useToast'
import { GetLetterPublicDto } from '~shared/dtos/letters.dto'
import { WIDTH_A4 } from '~utils/htmlUtils'

import { useGetPdfByPublicId } from '../hooks/letters.hooks'
import { SocialsButton } from './SocialsButton'

export const CitizenMenu = ({
  letter,
}: {
  letter?: GetLetterPublicDto
}): JSX.Element => {
  const {
    isOpen: isSharingOpen,
    onClose: onCloseSharing,
    onToggle: onToggleSharing,
  } = useDisclosure()
  const toast = useToast()
  const toastId = 'toastId-copied'

  const { getPdf, isPdfLoading } = useGetPdfByPublicId()

  if (!letter) return <></>
  const sharingMessage = `I've received a letter ${
    letter.issuingAgency?.shortName
      ? `from ${letter.issuingAgency.shortName} `
      : ''
  }via LetterSG, a digital letter platform from the Singapore Government: ${
    window.location.origin
  }/${letter.publicId}`

  const onCopy = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigator.clipboard.writeText(
      `${window.location.origin}/${letter.publicId}`,
    )
    if (!toast.isActive(toastId))
      toast({
        description: `Copied!`,
        status: 'success',
        duration: 10000,
        isClosable: true,
        id: toastId,
      })
  }

  return (
    <HStack
      w={WIDTH_A4}
      maxW={'90vw'}
      justifyContent="flex-end"
      pb={'10px'}
      pt={'16px'}
    >
      {letter.allowDownloads && (
        <>
          <Hide above="md">
            <IconButton
              icon={<BiDownload />}
              aria-label="share"
              variant="clear"
              onClick={
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                () => getPdf(letter.publicId)
              }
              size="sm"
              isLoading={isPdfLoading}
            >
              Download
            </IconButton>
          </Hide>
          <Show above="md">
            <Button
              leftIcon={<BiDownload />}
              aria-label="share"
              variant="outline"
              onClick={
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                () => getPdf(letter.publicId)
              }
              size="sm"
              isLoading={isPdfLoading}
            >
              Download
            </Button>
          </Show>
        </>
      )}
      <Hide above="md">
        <IconButton
          icon={<BiShareAlt />}
          aria-label="share"
          variant="clear"
          onClick={onToggleSharing}
          size="sm"
        >
          Share
        </IconButton>
      </Hide>
      <Show above="md">
        <Button
          leftIcon={<BiShareAlt />}
          aria-label="share"
          variant="outline"
          onClick={onToggleSharing}
          size="sm"
        >
          Share
        </Button>
      </Show>
      {isSharingOpen && (
        <Modal isOpen={isSharingOpen} onClose={onCloseSharing}>
          <ModalOverlay />
          <ModalContent mx={{ base: '1.5rem', md: 0 }}>
            <ModalHeader>
              <Text textStyle={'h4'}>Share letter</Text>
              <ModalCloseButton />
            </ModalHeader>
            <ModalBody>
              <VStack alignItems={'left'} spacing="0px" pt="10px">
                <VStack align={'left'} spacing="4px" mb="10px">
                  <Text
                    textStyle={'subhead-1'}
                    fontSize={'16px'}
                    textColor={'grey.500'}
                  >
                    Please share the letter link only with people you trust.
                    Your letter contents are visible to whoever has the letter
                    link.
                  </Text>
                </VStack>
                <InputGroup mb="5px">
                  <Input
                    placeholder={`${window.location.origin}/${letter.publicId}`}
                    isReadOnly
                    contentEditable={false}
                    onClick={onCopy}
                    _hover={{ cursor: 'pointer' }}
                  />
                  <InputRightElement>
                    <IconButton
                      colorScheme={'neutral'}
                      variant={'clear'}
                      icon={<BiCopy />}
                      size="sm"
                      aria-label={'copy'}
                      onClick={onCopy}
                      _hover={{ cursor: 'pointer' }}
                    />
                  </InputRightElement>
                </InputGroup>
                <VStack alignItems={'left'} pb="40px" pt="20px">
                  <Text textStyle={'h5'} mb="4px">
                    Or, share via:
                  </Text>
                  <HStack spacing={4}>
                    <SocialsButton
                      link={`mailto: ?body=${sharingMessage}&subject=Letter from LetterSG`}
                      onClick={onToggleSharing}
                      linkIcon={<FiMail />}
                      linkText={'Email'}
                      ariaLabel="email"
                    />
                    <SocialsButton
                      link={`sms: &body=${sharingMessage}`}
                      onClick={onToggleSharing}
                      linkIcon={<BiChat />}
                      linkText={'Messages'}
                      ariaLabel="sms"
                    />
                    <SocialsButton
                      link={`https://wa.me/?text=${sharingMessage}`}
                      onClick={onToggleSharing}
                      linkIcon={<BsWhatsapp />}
                      linkText={'WhatsApp'}
                      ariaLabel="whatsapp"
                    />
                  </HStack>
                </VStack>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </HStack>
  )
}
