import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  HStack,
  Icon,
  Link,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { BiStar } from 'react-icons/bi'
import { BsFillStarFill } from 'react-icons/bs'

import { FeedbackCategories } from '~shared/dtos/feedback.dto'

import { useSendFeedbackMutation } from '../hooks/feedback.hooks'

interface SendFeedbackModalProps {
  isOpen: boolean
  onClose: () => void
}

interface FeedbackFormData {
  feedbackRating: number
  feedbackText: string
}

export const SendFeedbackModal = ({
  isOpen,
  onClose,
}: SendFeedbackModalProps): JSX.Element => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<FeedbackFormData>({
    defaultValues: {
      feedbackRating: 0,
      feedbackText: '',
    },
  })

  const { mutateAsync, isLoading } = useSendFeedbackMutation({
    onSuccess: onClose,
  })

  const onSubmit = async (data: FeedbackFormData) => {
    await mutateAsync({
      feedbackName: FeedbackCategories.GENERAL,
      rating: data.feedbackRating,
      feedbackText: data.feedbackText,
    })
  }
  const ratingRange = [...Array(5).keys()].map((num) => num + 1)

  return (
    <Modal isOpen={isOpen} onClose={onClose} onCloseComplete={reset}>
      <ModalOverlay h="100%" />
      <form onSubmit={(event) => void handleSubmit(onSubmit)(event)}>
        <ModalContent>
          <ModalHeader>
            <Text textStyle={'h4'}>
              How was your experience using LetterSG?
            </Text>
            <ModalCloseButton onClick={onClose} />
          </ModalHeader>
          <ModalCloseButton />
          <VStack pb="32px" px="32px" spacing={'24px'} align={'left'} pt="5px">
            <FormControl isRequired isInvalid={!!errors.feedbackRating}>
              <Controller
                name={'feedbackRating'}
                control={control}
                defaultValue={undefined}
                rules={{
                  min: { value: 1, message: 'Please select a rating.' },
                  max: { value: 5, message: 'Please select a rating.' },
                }}
                render={({ field }) => (
                  <Flex gap="12px">
                    {ratingRange.map((rating) => (
                      <Icon
                        key={rating}
                        cursor={'pointer'}
                        onClick={() => field.onChange(rating)}
                        boxSize={'34px'}
                        mr={1}
                        as={field.value >= rating ? BsFillStarFill : BiStar}
                      />
                    ))}
                  </Flex>
                )}
              />
              <FormErrorMessage>
                {errors.feedbackRating && errors.feedbackRating.message}
              </FormErrorMessage>
            </FormControl>
            <VStack spacing="10px" align={'left'}>
              <Text textStyle={'subhead-1'}>
                Tell us about your experience{' '}
                {watch('feedbackRating') <= 3 ? '(Required)' : '(Optional)'}
              </Text>
              <FormControl
                isInvalid={!!errors.feedbackText}
                isRequired={watch('feedbackRating') <= 3}
              >
                <Textarea
                  isInvalid={!!errors.feedbackText}
                  fontSize={'md'}
                  placeholder={`Tell us how we're doing! Your feedback is important in shaping our services.`}
                  textStyle={'body-1'}
                  resize={'none'}
                  minHeight={'144px'}
                  {...register('feedbackText', {
                    pattern: {
                      value: /^[a-zA-Z0-9!-_.*'()\n ]+$/,
                      message:
                        'Only alphanumeric characters and certain special characters are allowed.',
                    },
                    maxLength: {
                      value: 500,
                      message: 'Exceeded character limit.',
                    },
                  })}
                />
                <FormHelperText textStyle={'body-2'} mt="8px">
                  {watch('feedbackText').length} / 500 characters left
                </FormHelperText>
                <FormErrorMessage>
                  {errors.feedbackText && errors.feedbackText.message}
                </FormErrorMessage>

                <Text mt="32px">
                  Got another template to onboard?{' '}
                  <Link
                    href="https://go.gov.sg/lettersg-returning-new-template"
                    isExternal
                    textColor="blue.500"
                  >
                    Contact us
                  </Link>
                  !
                </Text>
              </FormControl>
            </VStack>
          </VStack>
          <HStack marginLeft={'auto'} pb="32px" px="24px" spacing={'16px'}>
            <Button variant="ghost" mr={3} onClick={onClose} border={0}>
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              type={'submit'}
              isDisabled={!!errors.feedbackText || !!errors.feedbackRating}
              ml={'auto'}
              alignSelf="right"
            >
              Submit
            </Button>
          </HStack>
        </ModalContent>
      </form>
    </Modal>
  )
}
