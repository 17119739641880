import { Button, Text, useBreakpointValue } from '@chakra-ui/react'
import React from 'react'

import { ButtonCategoryProps } from './constants/feedback'

const ButtonCategory: React.FC<ButtonCategoryProps> = ({
  text,
  onClick,
  selectedColor = 'blue.500',
  selectedBackground = 'blue.100',
  selectedHover = 'blue.100',
  isSelected,
  isFlexNeeded,
  height,
  textStyle,
}) => {
  const hoverBg = useBreakpointValue({
    base: 'undefined', // No hover styles on mobile
    md: selectedHover, // Apply hover styles for medium screens and above
  })

  return (
    <Button
      size="md"
      display="flex"
      alignItems="center" // Ensure text is vertically centered
      onClick={onClick}
      bg={isSelected ? selectedBackground : 'blue.50'}
      border={isSelected ? '2px solid' : '1px solid'}
      height={height ? height : 'auto'}
      borderColor={isSelected ? selectedColor : 'gray.100'}
      color={isSelected ? selectedColor : 'gray.600'}
      _hover={{ bg: hoverBg }} // Apply hover styles based on screen size
      _active={{ bg: hoverBg }}
      borderRadius="md"
      flex={isFlexNeeded ? '1' : undefined} // Apply flex only if needed
      padding={isSelected ? 'calc(8px - 1px) calc(16px - 1px)' : '8px 16px'} // Adjust padding to compensate for border size increase or decrease, so button stays in position
      boxSizing="border-box"
    >
      <Text textStyle={textStyle}>{text}</Text>
    </Button>
  )
}

export default ButtonCategory
