import { useEffect, useState } from 'react'

export enum MESSAGE_TYPE {
  ADD_SUCCESS = 'ADD_SUCCESS',
  REMOVE_SUCCESS = 'REMOVE_SUCCESS',
  REMOVE_ERROR = 'REMOVE_ERROR',
}

export const useAutoClearMessage = (duration = 3000) => {
  const [message, setMessage] = useState({ text: '', type: '' })

  useEffect(() => {
    if (message.text !== '') {
      const timer = setTimeout(
        () => setMessage({ text: '', type: '' }),
        duration,
      )
      return () => clearTimeout(timer)
    }
  }, [message, duration])

  const clearMessage = () => setMessage({ text: '', type: '' })
  return { message, setMessage, clearMessage }
}
