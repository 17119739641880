import { Button } from '@chakra-ui/react'
import React from 'react'

interface SubmitButtonProps {
  isDisabled: boolean
  type: 'button' | 'submit' | 'reset' | undefined
  isLoading: boolean
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  isDisabled,
  type,
  isLoading,
}) => {
  return (
    <Button
      isLoading={isLoading}
      type={type}
      isDisabled={isDisabled}
      mt="4"
      variant="ghost"
      border="none"
      color="blue.500"
      width="100%"
      _disabled={{
        backgroundColor: 'transparent',
        color: 'gray.500',
        cursor: 'not-allowed',
      }}
    >
      Submit
    </Button>
  )
}

export default SubmitButton
