import DOMPurify from 'isomorphic-dompurify'

// DOMPurify settings here should match those in the lambda function to generate PDF
DOMPurify.setConfig({
  USE_PROFILES: { html: true }, // disallow SVG and MathML, allow only HTML
  IN_PLACE: true, // in place replacement meant to be faster https://github.com/cure53/DOMPurify#influence-where-we-sanitize
  FORCE_BODY: true, // allow elements like `style` to be used in body
})

export function sanitizeHtml(html: string) {
  return DOMPurify.sanitize(html)
}

export function escapeHtml(text: string) {
  return text
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
}
