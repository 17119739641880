import { Flex, Heading, Image, Text, VStack } from '@chakra-ui/react'

import NotFoundSvg from '~/assets/NotFound.svg'

interface PublicNotFoundComponentProps {
  heading?: string
  subtitle?: string
}

export const PublicNotFoundComponent = ({
  heading,
  subtitle,
}: PublicNotFoundComponentProps): JSX.Element => {
  return (
    <Flex pt={40} justify="center" minHeight="100vh">
      <VStack spacing={2} maxW={'90%'}>
        <Image src={NotFoundSvg} aria-hidden h={'50%'} pb={8} />
        <Heading size="lg">{heading ?? 'Letter could not be found.'}</Heading>
        <Text textAlign="center">
          It seems like you have stumbled upon a missing page.
        </Text>
        <Text textAlign="center">
          {subtitle ??
            'Please double check your letter link or contact the agency who has given you the link.'}
        </Text>
      </VStack>
    </Flex>
  )
}
