import { jsPDF } from 'jspdf'

import { ACCEPTED_EDITED_TEMPLATE_FIELDS_REGEX } from '~shared/constants/regex'
import { getTemplateFields, parseTemplateField } from '~shared/util/templates'

// Pixel ratios of width to height of A4 letters
export const WIDTH_A4 = 794
export const HEIGHT_A4 = 1122

// Highlight HTML consts
const CUSTOM_HIGHLIGHT_HTML_END = '</mark>'
const CUSTOM_ERROR_HIGHLIGHT_HTML = `<mark style="background-color: rgba(255, 0, 0, 0.3)">`
const CUSTOM_HIGHLIGHT_HTML = `<mark style="background-color: rgba(255, 240, 0, 0.5)">`

// Additional wrapper div to resize contents of letters
const resizeToA4Dimensions = (
  htmlString: string,
  width = WIDTH_A4,
  height = HEIGHT_A4,
): string => `<div style="width: ${width}px; height: ${height}px;"> 
  ${htmlString}</div>
`

// Convert HTML to PDF
export const convertHtmlToPdf = (
  htmlContent: string,
  pdfPath: string,
): Promise<void> => {
  // Create a new jsPDF instance
  const pdf = new jsPDF({ format: 'A4', compress: true })
  const htmlString = resizeToA4Dimensions(htmlContent)
  return new Promise((resolve) => {
    void pdf.html(htmlString, {
      callback: function () {
        // Save the PDF document
        pdf.save(pdfPath)
        resolve()
      },
      width: pdf.internal.pageSize.getWidth(), // A4 page width
      windowWidth: WIDTH_A4, // element width
    })
  })
}

// Detect when all images within a html element load, and trigger provided callback
export const onHtmlImagesLoaded = (
  node: HTMLDivElement,
  callback: () => void,
  maxTimeToWaitInMs = 1000,
) => {
  const images = node.getElementsByTagName('img')
  if (images.length === 0) {
    callback()
    return
  }

  let loadedImages = 0

  const checkAllLoaded = () => {
    loadedImages++
    if (loadedImages === images.length) {
      clearTimeout(timeoutId) // Clear the timeout if all images are loaded.
      callback()
    }
  }

  const timeoutId = setTimeout(callback, maxTimeToWaitInMs)

  for (const img of images) {
    if (img.complete) {
      checkAllLoaded()
    } else {
      img.addEventListener('load', checkAllLoaded)
    }
  }
}

export const highlightVariablesInHTMLTemplate = (htmlContent: string) => {
  let highlightedHTML = htmlContent
  const templateFields = getTemplateFields(htmlContent)

  // Regular expression to find all occurrences of {{...}}
  const regex = /{{.*?}}/g
  const matches = htmlContent.match(regex)

  // Create a Set to keep track of already highlighted field names for errors, so that we do not highlight them further
  const highlightedErrorSet = new Set<string>()

  matches?.forEach((match) => {
    // Extract the content inside {{...}}
    const fieldName = match.slice(2, -2) // Remove '{{' and '}}'

    // If the fieldName is not in templateFields and has not been highlighted yet
    if (
      !templateFields.includes(fieldName) &&
      !highlightedErrorSet.has(fieldName)
    ) {
      // Create a regex to match the pattern inside > ... < without using backhand assertions
      // Capture groups are not compatible with older versions of browsers
      const insideTagRegex = new RegExp(`>[^<]*{{${fieldName}}}[^<]*<`, 'g')

      // Replace with error-highlighted HTML if the match is found inside a tag
      highlightedHTML = highlightedHTML.replaceAll(insideTagRegex, (match) => {
        // Add the fieldName to the highlightedSet
        highlightedErrorSet.add(fieldName)

        // Apply error highlighting by adding the custom HTML tags
        return match.replace(
          `{{${fieldName}}}`,
          `${CUSTOM_ERROR_HIGHLIGHT_HTML}{{${fieldName}}}${CUSTOM_HIGHLIGHT_HTML_END}`,
        )
      })
    }
  })

  // For all the ones that are valid, highlight them with yellow highlighting
  // This one don't need a Set because we are targetting directly the line by line and extracting each {{ }}
  templateFields.forEach((match) => {
    // Create a regex to match the content inside > ... < without using backhand assertion
    const insideTagRegex = new RegExp(`>.*?<`, 'g')

    // Replace with highlighted HTML if the match is found
    highlightedHTML = highlightedHTML.replace(insideTagRegex, (fullMatch) => {
      // Find the part inside the tags
      const innerContent = fullMatch.slice(1, -1) // Extract content between '>' and '<'

      // Replace all occurrences of {{match}} within the content
      const updatedContent = innerContent.replace(
        new RegExp(`{{${match}}}`, 'g'),
        `${CUSTOM_HIGHLIGHT_HTML}{{${match}}}${CUSTOM_HIGHLIGHT_HTML_END}`,
      )

      // Reconstruct the tag with the updated content
      return `>${updatedContent}<`
    })
  })

  return highlightedHTML
}

export const sanitizeTemplateVariablesBeforeSaving = (html: string): string => {
  return html.replaceAll(
    ACCEPTED_EDITED_TEMPLATE_FIELDS_REGEX,
    function (match, rest: string): string {
      return match ? '{{' + parseTemplateField(match.slice(2, -2)) + '}}' : rest
    },
  )
}
