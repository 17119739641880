import { useFeatureValue } from '@growthbook/growthbook-react'
import { Banner } from '@opengovsg/design-system-react'
import Markdown from 'react-markdown'

import { GrowthBookFeatures } from '~shared/types/feature-flag'

export const OfficerAnnouncementBanner = () => {
  // fetch banner text from GrowthBook
  const announcementText = useFeatureValue(
    GrowthBookFeatures.announcementBanner,
    '',
  )
  if (!announcementText) return <></>
  return (
    <Banner variant="info" isDismissable={false}>
      <Markdown>{announcementText}</Markdown>
    </Banner>
  )
}
