import {
  Box,
  ListItem,
  OrderedList,
  Table,
  TableContainer,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react'

import { HeaderCell } from '~features/dashboard/components/tableComponents/HeaderCell'
import { BulkLetterValidationResultError } from '~shared/dtos/letters.dto'

interface UploadCsvErrorsTableProps {
  uploadCsvErrors: BulkLetterValidationResultError[]
}

export const UploadCsvErrorsTable = ({
  uploadCsvErrors,
}: UploadCsvErrorsTableProps): JSX.Element => {
  const errorsByRow = uploadCsvErrors.reduce(
    (errorGroup: Array<BulkLetterValidationResultError[]>, item) => {
      if (errorGroup[item.id]) {
        errorGroup[item.id].push(item)
      } else {
        errorGroup[item.id] = [item]
      }
      return errorGroup
    },
    new Array<BulkLetterValidationResultError[]>(uploadCsvErrors.length),
  )

  const errorTableRows = errorsByRow.map((errorData, index) => {
    return (
      <Tr key={JSON.stringify(index)}>
        {/* We add 2 because the first row with data in the CSV is row 2, even though it has index 0 */}
        <Td>
          <b>{index + 2}</b>
        </Td>
        <Td whiteSpace="normal">
          <OrderedList>
            {errorData.map((error, index) => {
              return (
                <ListItem key={index}>
                  <Text textOverflow={'wrap'}>
                    {/* Add 1 because index starts from 0 actually */}
                    {error.param.toString()} {error.displayedErrorMessage}
                  </Text>
                </ListItem>
              )
            })}
          </OrderedList>
        </Td>
      </Tr>
    )
  })

  return (
    <Box paddingTop={4}>
      <TableContainer w="100%">
        <Table variant="simple">
          <Tr backgroundColor="base.divider.medium">
            <HeaderCell>Row #</HeaderCell>
            <HeaderCell>Errors</HeaderCell>
          </Tr>
          {errorTableRows}
        </Table>
      </TableContainer>
    </Box>
  )
}
