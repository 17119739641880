import { Box } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'

import { AdminAuthProvider } from '~features/auth/context/AdminProtectedContext'

export const AppLayout = () => {
  return (
    <AdminAuthProvider>
      <Box h="$100vh">
        <Outlet />
      </Box>
    </AdminAuthProvider>
  )
}
