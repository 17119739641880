import { useMutation, useQueryClient } from '@tanstack/react-query'
import { WretchError } from 'wretch/resolver'

import { adminQueryKeys } from '~constants/query-keys'
import { useToast } from '~hooks/useToast'
import { api } from '~lib/api'
import {
  CreateTemplateDto,
  GetTemplateDto,
  UpdateTemplateDto,
} from '~shared/dtos/templates.dto'

export const useCreateTemplateMutation = ({
  onSuccess,
}: {
  onSuccess?: (res: GetTemplateDto) => void
} = {}) => {
  const queryClient = useQueryClient()
  const toast = useToast()

  return useMutation(
    async (body: CreateTemplateDto): Promise<GetTemplateDto> => {
      try {
        return await api.url(`/templates`).post(body).json<GetTemplateDto>()
      } catch (error) {
        throw new Error('Failed to create template')
      }
    },
    {
      onSuccess: async (res: GetTemplateDto) => {
        await queryClient.invalidateQueries(adminQueryKeys.templates())
        onSuccess?.(res)
      },
      onError: () => {
        toast({
          description: 'Failed to create template',
          status: 'error',
        })
      },
    },
  )
}

export const useUpdateTemplateMutation = ({
  onSuccess,
}: {
  onSuccess?: (res: GetTemplateDto) => void
} = {}) => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(
    async (body: UpdateTemplateDto): Promise<GetTemplateDto> => {
      return await api
        .url(`/templates/${body.templateId}`)
        .put(body)
        .json<GetTemplateDto>()
    },
    {
      onSuccess: async (res: GetTemplateDto) => {
        onSuccess?.(res)
        await queryClient.invalidateQueries({
          queryKey: adminQueryKeys.templates(),
        })
        toast({
          title: 'Template saved successfully!',
          status: 'success',
        })
      },
      onError: (err: WretchError) => {
        toast({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          title: err.json?.message as string,
          status: 'error',
        })
      },
    },
  )
}

export const useCreatePdfPreview = () => {
  const toast = useToast()

  const { mutate, isLoading } = useMutation(
    async (body: { html: string }) =>
      api.url(`/templates/pdfs`).post(body).blob(),
    {
      onSuccess: (blob) => {
        const pdfUrl = URL.createObjectURL(blob)
        window.open(pdfUrl, '_blank')
      },
      onError: () => {
        toast({
          description: 'Failed to create the PDF preview, please try again.',
          status: 'error',
        })
      },
    },
  )
  return { createPdfPreview: mutate, isPdfPreviewLoading: isLoading }
}
