import { Box, Flex, Heading, Image, Stack } from '@chakra-ui/react'
import { Suspense } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import LogoSvg from '~/assets/Logo.svg'
import { Loading } from '~/components/Loading'
import { routes } from '~/constants/routes'

import { UserMenu } from './components/UserMenu'

const NavbarBody = (): JSX.Element => {
  return (
    <Box
      w="100%"
      h="admin-header-height"
      borderBottom="1px"
      borderBottomColor="base.divider.medium"
      backgroundColor={'slate.900'}
      position="static"
      top={0}
    >
      <Flex
        flexDir="row"
        px={8}
        py={2}
        align="center"
        justifyContent="space-between"
      >
        <RouterLink to={routes.index}>
          <div id="navbar-logo">
            <Image maxW={7} src={LogoSvg} />
          </div>
        </RouterLink>
        <UserMenu />
      </Flex>
    </Box>
  )
}

export const Navbar = () => (
  <Suspense fallback={<Loading />}>
    <NavbarBody />
  </Suspense>
)
