import {
  Divider,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'

import { useGetTemplateHistory } from '~features/editor/hooks/templateHistory.hooks'
import { getLocaleTimeFromDate, parseDate } from '~utils/dateUtils'
import { templateActionTypeToString } from '~utils/stringUtils'

interface TemplateHistoryDrawerProps {
  isOpen: boolean
  onClose: () => void
  templateId: number
}

export const TemplateHistoryDrawer = ({
  isOpen,
  onClose,
  templateId,
}: TemplateHistoryDrawerProps) => {
  const { templateHistory } = useGetTemplateHistory(templateId)
  return (
    <Drawer size="sm" isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent w="1200px">
        <DrawerCloseButton />
        <DrawerHeader padding="20px" paddingTop={'28px'}>
          <Text textStyle="subhead-3">ACTIVITY HISTORY</Text>
        </DrawerHeader>
        <VStack align="left" paddingLeft={'20px'} spacing="0" overflow="auto">
          {templateHistory && templateHistory.length > 0 ? (
            templateHistory.map((templateHistoryData, index) => (
              <HStack spacing="15px" key={index}>
                <Divider
                  orientation="vertical"
                  bgColor={'grey.100'}
                  borderColor={'grey.100'}
                  height="55px"
                  borderWidth={'1px'}
                />
                <VStack align="left" spacing="0">
                  <Text
                    textStyle={'subhead-2'}
                    textColor={'grey.300'}
                    fontSize={'14px'}
                  >
                    {templateActionTypeToString(templateHistoryData.actionType)}{' '}
                    {templateHistoryData.modifiedBy}
                  </Text>
                  <Text
                    textStyle={'subhead-2'}
                    textColor={'grey.300'}
                    fontSize={'12px'}
                  >
                    {parseDate(templateHistoryData.updatedAt.toString())}
                    {', '}
                    {getLocaleTimeFromDate(
                      templateHistoryData.updatedAt.toString(),
                    )}
                  </Text>
                </VStack>
              </HStack>
            ))
          ) : (
            <Text
              textStyle={'subhead-2'}
              textColor={'grey.300'}
              fontSize={'14px'}
            >
              No previous versions available
            </Text>
          )}
        </VStack>
      </DrawerContent>
    </Drawer>
  )
}
