import { Flex, Spinner } from '@chakra-ui/react'

export const Loading = ({ fullscreen = false }: { fullscreen?: boolean }) => (
  <Flex
    w="full"
    h={fullscreen ? '100vh' : '100%'}
    justify="center"
    align="center"
    rounded="md"
  >
    <Spinner />
  </Flex>
)
