import {
  Box,
  Button,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import LetterConfirmation from '~/assets/LetterConfirmation.svg'
import { GetBatchDto } from '~shared/dtos/batches.dto'
import { CitizenNotificationMethod } from '~shared/dtos/letters.dto'
import { GetTemplateDto } from '~shared/dtos/templates.dto'
import {
  citizenNotificationMethodToString,
  pluraliseIfNeededForCount,
} from '~utils/stringUtils'

interface ModalBodyProps {
  header: string
  content: string | number
}
interface BulkIssueSendLettersConfirmationModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirmSendLetters: () => Promise<GetBatchDto>
  isLoading: boolean
  letterCount: number
}

const ModalBody = ({ header, content }: ModalBodyProps): JSX.Element => {
  return (
    <Text
      textColor="grey.500"
      fontFamily="Inter"
      fontSize="17px"
      fontStyle="normal"
      fontWeight="500"
      lineHeight="24px"
      mb="3"
    >
      {header}
      <Text
        fontFamily="Inter"
        fontSize="17px"
        fontStyle="normal"
        lineHeight="24px"
        mb="3"
        textColor={'#2C2E34'}
      >
        {content}
      </Text>
    </Text>
  )
}

export const BulkIssueSendLettersConfirmationModal = ({
  isOpen,
  onClose,
  onConfirmSendLetters,
  isLoading,
  letterCount,
}: BulkIssueSendLettersConfirmationModalProps): JSX.Element => {
  const { getValues } = useFormContext()
  const citizenNotificationMethod = getValues(
    'citizenNotificationMethod',
  ) as CitizenNotificationMethod
  const selectedTemplate = getValues('selectedTemplate') as GetTemplateDto

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton onClick={onClose} />
        <Image
          alignSelf="center"
          borderTopRadius={'8px'}
          src={LetterConfirmation}
        />
        <VStack p="32px" alignItems={'left'}>
          <Text textStyle={'h4'} mb="5">
            {citizenNotificationMethod == CitizenNotificationMethod.NONE
              ? 'Please review these details before downloading the letter links.'
              : 'Sending letters cannot be undone. Please review these details carefully.'}
          </Text>
          <Box
            bg="utility.feedback.info-subtle"
            padding="24px"
            style={{ marginTop: 0 }}
            borderRadius={'4px'}
          >
            <ModalBody header="Template:" content={selectedTemplate?.name} />
            <ModalBody
              header="Delivery method:"
              content={
                citizenNotificationMethod == CitizenNotificationMethod.NONE
                  ? 'Other services (e.g. Postman)'
                  : `via ${citizenNotificationMethodToString(
                      citizenNotificationMethod,
                    )}`
              }
            />
            <ModalBody header="No. of letters:" content={letterCount} />
          </Box>
        </VStack>
        <ModalFooter pt="0" px="24px" pb="32px">
          <Button
            variant="ghost"
            mr={3}
            onClick={onClose}
            border={0}
            colorScheme="blue"
          >
            Cancel
          </Button>
          <Button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onConfirmSendLetters}
            isLoading={isLoading}
          >
            {citizenNotificationMethod == CitizenNotificationMethod.NONE
              ? `Download letter links`
              : `Send ${letterCount} ${pluraliseIfNeededForCount(
                  letterCount,
                  'letter',
                )}`}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
