import {
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { useForm, useFormContext } from 'react-hook-form'

import { useSendSampleSmsMutation } from '~features/issue/hooks/templates.hooks'
import { ACCEPTED_SINGAPORE_PHONE_NUMBERS_REGEX_WITHOUT_65 } from '~shared/constants/regex'
import { isGovSgOrWhitelistedEmail } from '~shared/decorators/is-gov-sg-or-whitelisted-email'
import { CitizenNotificationMethod } from '~shared/dtos/letters.dto'
import { GetTemplateDto } from '~shared/dtos/templates.dto'

interface FormData {
  recipient: string
}
export const SendSampleForm = (): JSX.Element => {
  const { getValues } = useFormContext()

  const citizenNotificationMethod = getValues(
    'citizenNotificationMethod',
  ) as CitizenNotificationMethod
  const selectedTemplate = getValues('selectedTemplate') as GetTemplateDto

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<FormData>({ mode: 'onBlur' })

  // reset send sample form if the notification method changes
  useEffect(() => {
    reset()
  }, [citizenNotificationMethod])

  const { mutateAsync, isLoading } = useSendSampleSmsMutation()

  const onSubmit = async (data: FormData): Promise<void> => {
    if (!selectedTemplate) return
    await mutateAsync({
      templateId: selectedTemplate?.id,
      createNotificationDto: {
        recipients: [data.recipient],
        notificationMethod: citizenNotificationMethod,
      },
    })
  }

  return (
    <form
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormControl
        isRequired
        isInvalid={!!errors?.recipient}
        isReadOnly={isLoading}
      >
        <VStack alignItems="left">
          <HStack>
            {citizenNotificationMethod === CitizenNotificationMethod.SMS && (
              <InputGroup borderColor="grey.200" width="100%">
                <InputLeftAddon bg="white">
                  <Text>+65</Text>
                </InputLeftAddon>
                <Input
                  type="tel"
                  placeholder="0000 0000"
                  {...register('recipient', {
                    pattern: {
                      value: ACCEPTED_SINGAPORE_PHONE_NUMBERS_REGEX_WITHOUT_65,
                      message: 'Phone number should be local SG number',
                    },
                  })}
                />
              </InputGroup>
            )}
            {citizenNotificationMethod === CitizenNotificationMethod.EMAIL && (
              <InputGroup borderColor="grey.200">
                <Input
                  type="email"
                  placeholder="example@open.gov.sg"
                  {...register('recipient', {
                    validate: {
                      validOfficerEmail: (email) =>
                        isGovSgOrWhitelistedEmail(email) === true ||
                        'Email should be a valid public officer email',
                    },
                  })}
                />
              </InputGroup>
            )}
            <Button
              isDisabled={
                !!errors.recipient ||
                !citizenNotificationMethod ||
                citizenNotificationMethod === CitizenNotificationMethod.NONE
              }
              isLoading={isLoading}
              type="submit"
              variant="outline"
            >
              Send test letter
            </Button>
          </HStack>
          <FormErrorMessage>{errors?.recipient?.message}</FormErrorMessage>
        </VStack>
      </FormControl>
    </form>
  )
}
