import { Button } from '@chakra-ui/react'
import React from 'react'
import { BiX } from 'react-icons/bi'

interface CloseButtonProps {
  onClick: () => void
  isAlignToTopRightBox?: boolean
}

const CloseButton: React.FC<CloseButtonProps> = ({
  onClick,
  isAlignToTopRightBox = false,
}) => {
  return (
    <Button
      onClick={onClick}
      variant="unstyled"
      border="none"
      aria-label="Close feedback box"
      display={isAlignToTopRightBox ? 'flex' : undefined} // Enable flex layout, needed so that I can display the cross on the top right
      justifyContent={isAlignToTopRightBox ? 'flex-end' : undefined}
      alignItems={isAlignToTopRightBox ? 'flex-start' : undefined}
    >
      <BiX size={'1.7rem'} />
    </Button>
  )
}

export default CloseButton
