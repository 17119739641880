import { Box, Table, Td, Tr, useDisclosure } from '@chakra-ui/react'

import { GetLetterDto } from '~shared/dtos/letters.dto'

import { HeaderCell } from '../tableComponents/HeaderCell'

interface IssuedBatchDetailsKeywordsTableProps {
  headers: string[]
  letters: GetLetterDto[]
  rowHeight: string
}
export const IssuedBatchDetailsKeywordsTable = ({
  headers,
  letters,
  rowHeight,
}: IssuedBatchDetailsKeywordsTableProps) => {
  const drawerDisclosure = useDisclosure()
  return (
    <Table variant="simple" size="sm">
      <Tr textStyle={'subhead-2'} backgroundColor={'grey.50'}>
        {headers &&
          headers.map((header) => (
            <HeaderCell key={header}>{header}</HeaderCell>
          ))}
      </Tr>

      {letters &&
        letters.map((letter) => {
          const details = Object.values(
            JSON.parse(letter?.fieldValues ?? '{}') as {
              [key: string]: string
            },
          )
          return (
            <Box
              key={letter.publicId}
              display="table-row"
              onClick={() => {
                drawerDisclosure.onOpen()
              }}
              textColor="default"
              textStyle={'body-2'}
              height={rowHeight}
              backgroundColor={'standard.white'}
            >
              {details &&
                details.map((detail) => (
                  <Td key={detail} h={'41px'} verticalAlign={'middle'}>
                    {detail}
                  </Td>
                ))}
            </Box>
          )
        })}
    </Table>
  )
}
