export const CONTACT_US = 'https://go.gov.sg/lettersg-support'
export const USER_GUIDE = 'https://go.gov.sg/lettersg-guide'
export const TERMS_OF_USE = 'https://guide.letters.gov.sg/legal/terms-of-use'
export const REPORT_VULNERABILITY =
  'https://www.tech.gov.sg/report_vulnerability'
export const PRIVACY = 'https://guide.letters.gov.sg/legal/privacy-policy'
export const REQUEST_NEW_TEMPLATE =
  'https://go.gov.sg/lettersg-onboarding-new-template'
export const USER_GUIDE_EDITOR =
  'https://guide.letters.gov.sg/for-agency-users/editing-letter-templates#what-can-i-do-with-the-editor'
