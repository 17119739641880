import { VStack } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'

import { AppFooter } from '~/app/AppFooter'

export const PublicLayout = () => {
  return (
    <VStack minWidth="100%" align="stretch" spacing={0}>
      <Outlet />
      <AppFooter />
    </VStack>
  )
}
