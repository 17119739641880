import { IconButton } from '@chakra-ui/react'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'

export enum ToggleButtonDirection {
  Left,
  Right,
}

interface ToggleTemplateButtonProps {
  direction: ToggleButtonDirection
  isDisabled: boolean
  onButtonClick: () => void
}
export const ToggleTemplateButton = ({
  direction,
  isDisabled,
  onButtonClick,
}: ToggleTemplateButtonProps) => {
  return (
    <IconButton
      pointerEvents="auto"
      hidden={isDisabled}
      isRound={true}
      variant={'clear'}
      icon={
        direction === ToggleButtonDirection.Left ? (
          <BiChevronLeft color="black" />
        ) : (
          <BiChevronRight color="black" />
        )
      }
      aria-label={direction === ToggleButtonDirection.Left ? 'Left' : 'Right'}
      shadow={'sm'}
      backgroundColor={'standard.white'}
      _hover={{
        cursor: 'pointer',
        border: '1px solid',
        borderColor: 'interaction.main.default',
      }}
      border={'none'}
      onClick={onButtonClick}
      position={'fixed'}
      top={'52vh'}
      left={direction === ToggleButtonDirection.Left ? '10vw' : undefined}
      right={direction === ToggleButtonDirection.Right ? '10vw' : undefined}
    />
  )
}
