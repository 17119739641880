// adapted from https://github.com/opengovsg/calsg/blob/develop/frontend/src/features/common/landing/LandingPage.tsx
import { Box, Flex } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppFooter } from '~/app/AppFooter'

import { useAdminAuth } from '../auth/context/AdminProtectedContext'
import { LandingPageToggleViewButton } from './components/LandingPageToggleViewButton'
import { LandingSection } from './components/LandingSection'
import { PublicHeader } from './components/PublicHeader'
import { useGetIsGsibUser } from './hooks/landing.hooks'
import { AllOgpProductsSection } from './sections/AllOgpProductsSection'
import { IntroductionSection } from './sections/IntroductionSection'
import { ProductHighlightsSection } from './sections/ProductHighlightsSection'
import { SampleUseCaseSection } from './sections/SampleUseCaseSection'
import { StatisticsSection } from './sections/StatisticsSection'

export const LandingPage = (): JSX.Element => {
  const { adminUser } = useAdminAuth()
  const navigate = useNavigate()
  const { isGsibUser } = useGetIsGsibUser()
  const [isAgencySelected, setIsAgencySelected] = useState<boolean>(
    isGsibUser ? true : false,
  )

  // If user is already logged in, redirect to dashboard.
  useEffect(() => {
    if (adminUser) navigate('/admin/letters')
  }, [navigate, adminUser])

  const [sampleUseCaseSectionOffset, setSampleUseCaseSectionOffset] =
    useState<number>()
  const sampleUseCaseSection = useRef(null)
  useEffect(() => {
    sampleUseCaseSection.current &&
      setSampleUseCaseSectionOffset(sampleUseCaseSection.current['offsetTop'])
  }, [sampleUseCaseSection.current])

  return (
    <Flex flexDir="column">
      <PublicHeader />
      <LandingSection bg={'standard.white'}>
        <IntroductionSection
          sampleUseCaseSectionOffset={sampleUseCaseSectionOffset}
          isGsibUser={isGsibUser ?? true}
        />
      </LandingSection>
      <LandingPageToggleViewButton
        isAgencySelected={isAgencySelected}
        setIsAgencySelected={setIsAgencySelected}
      />
      <LandingSection>
        <ProductHighlightsSection isAgencyView={isAgencySelected} />
      </LandingSection>
      <LandingSection bg={'standard.white'}>
        <StatisticsSection />
      </LandingSection>
      <Box ref={sampleUseCaseSection}>
        <LandingSection bg={'blue.700'}>
          <SampleUseCaseSection />
        </LandingSection>
      </Box>
      <LandingSection>
        <AllOgpProductsSection />
      </LandingSection>
      <AppFooter />
    </Flex>
  )
}
