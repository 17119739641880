import {
  Badge,
  Box,
  HStack,
  Table,
  Td,
  Text,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react'
import { Link } from '@opengovsg/design-system-react'
import { BiLink } from 'react-icons/bi'
import { MdInfo } from 'react-icons/md'
import { Link as RouterLink } from 'react-router-dom'

import {
  CitizenNotificationMethod,
  GetLetterDto,
} from '~shared/dtos/letters.dto'
import { NotificationStatus } from '~shared/dtos/notifications.dto'

import { HeaderCell } from './HeaderCell'

interface IssuedBatchMainTableProps {
  letters: GetLetterDto[]
  rowHeight: string
  notificationMethod?: CitizenNotificationMethod
}

export const IssuedBatchDetailsMainTable = ({
  letters,
  rowHeight,
  notificationMethod,
}: IssuedBatchMainTableProps) => {
  return (
    <Table
      variant="simple"
      position={'sticky'}
      left={'0'}
      boxShadow={'sm'}
      size="sm"
      // TODO: clean this up later, there's a flex styling that overrides any width setting that I could only get around for now by setting min and max width
      maxW="400px"
      minW="400px"
    >
      <Tr textStyle={'subhead-2'} backgroundColor={'grey.50'}>
        <HeaderCell>
          ID link{notificationMethod ? ' & recipient' : ''}
        </HeaderCell>
        <HeaderCell>
          <HStack>
            <Text>Status</Text>
            <Box position="relative" float="right" display="flex">
              <Tooltip
                label="Status will only be updated to read when viewed by a non-admin user"
                placement="bottom"
                fontSize={'sm'}
                size="xs"
                hasArrow
                backgroundColor={'grey.900'}
              >
                <div style={{ display: 'block', width: '100%' }}>
                  <MdInfo
                    style={{
                      float: 'right',
                    }}
                  />
                </div>
              </Tooltip>
            </Box>
          </HStack>
        </HeaderCell>
      </Tr>
      {letters &&
        letters.map((letter) => {
          return (
            <Box
              backgroundColor={'standard.white'}
              key={letter.publicId}
              display="table-row"
              textColor="default"
              textStyle={'subhead-2'}
              height={rowHeight}
            >
              <Td verticalAlign={'middle'}>
                <VStack align={'left'}>
                  <Link
                    as={RouterLink}
                    to={`/${letter.publicId}`}
                    textDecoration="none"
                    textColor="default"
                    _hover={{
                      color: 'interaction.main.default',
                    }}
                    externalLinkIcon={<BiLink size={'18px'} />}
                    isExternal
                  >
                    <Text paddingRight={2}>{letter.publicId}</Text>
                  </Link>
                  {notificationMethod ? (
                    <Text color={'grey.400'}>{letter.recipient}</Text>
                  ) : (
                    <></>
                  )}
                </VStack>
              </Td>
              <Td verticalAlign={'middle'}>
                <HStack>
                  {letter.firstReadAt ? (
                    <Badge
                      variant="subtle"
                      borderRadius={'12px'}
                      colorScheme="green"
                    >
                      Read
                    </Badge>
                  ) : letter.notificationStatus === NotificationStatus.FAIL ||
                    letter.notificationStatus ===
                      NotificationStatus.INVALID_RECIPIENT ? (
                    <Badge
                      variant="subtle"
                      colorScheme="orange"
                      borderRadius={'12px'}
                    >
                      Bounced
                    </Badge>
                  ) : (
                    <Badge
                      variant="subtle"
                      colorScheme="grey"
                      borderRadius={'12px'}
                    >
                      Unread
                    </Badge>
                  )}
                </HStack>
              </Td>
            </Box>
          )
        })}
    </Table>
  )
}
