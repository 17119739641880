import { useQuery } from '@tanstack/react-query'

import { api } from '~lib/api'

export const useGetIsGsibUser = () => {
  const { data } = useQuery(['is-gsib-user'], () => {
    return api.url('/public/is-intranet').get().json<boolean>()
  })
  return {
    isGsibUser: data,
  }
}
