export const routes = {
  index: '/',
  public: {
    letters: 'letters',
    error: 'error',
  },
  admin: {
    index: 'admin',
    login: 'login',
    api: 'api-integration',
    templates: {
      index: 'templates',
      archive: 'archives',
      create: 'create',
      preview: 'preview',
      edit: 'edit',
    },
    letters: 'letters',
    batches: 'batches',
  },
}
