import { Box, VStack } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'

import { AppFooter } from '~/app/AppFooter'
import { Navbar } from '~components/Navbar/Navbar'

export const AdminLayout = () => {
  return (
    <VStack minWidth="100%" align="stretch" spacing={0}>
      <Navbar />
      <Box px={{ lg: '10%', base: '5%' }} minH="admin-content-min-height">
        <Outlet />
      </Box>
      <AppFooter containerProps={{ px: '3rem' }} />
    </VStack>
  )
}
