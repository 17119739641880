import { Box, Center, HStack, Icon, Text, VStack } from '@chakra-ui/react'
import { Pagination } from '@opengovsg/design-system-react'
import { useEffect, useMemo } from 'react'
import { BiArrowBack } from 'react-icons/bi'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Loading } from '~components/Loading'
import { useGetTemplates } from '~features/dashboard/hooks/dashboard.hooks'

import { TemplatesBody } from './components/TemplatesBody'

export const PAGE_SIZE = 16
const DEFAULT_PAGE_SEARCH_PARAM = '1'

export const ArchivedTemplatesPage = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams()
  const currentPage = useMemo(
    () =>
      parseInt(searchParams.get('currentPage') || DEFAULT_PAGE_SEARCH_PARAM) -
      1,
    [searchParams],
  )

  // see https://stackoverflow.com/questions/33188994/scroll-to-the-top-of-the-page-after-render-in-react-js
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const navigate = useNavigate()

  const { templates, isTemplatesLoading, count } = useGetTemplates({
    limit: PAGE_SIZE,
    offset: currentPage * PAGE_SIZE,
    isArchived: true,
  })

  if (isTemplatesLoading) {
    return <Loading fullscreen={true} />
  }

  return (
    // TODO: consider abstracting the layout into a separate component to be shared with TemplatesPage
    <VStack py={'50px'} align={'start'}>
      <HStack
        w={'100%'}
        mb={'15px'}
        paddingBottom={'36px'}
        justifyContent="space-between"
      >
        <VStack align={'left'}>
          <HStack spacing={3}>
            <Icon
              as={BiArrowBack}
              onClick={() => navigate('/admin/templates')}
              boxSize={5}
              aria-label="back-to-dashboard"
            />
            <Text textStyle={'h3'}>Archived templates</Text>
          </HStack>
          <Text>
            Letters previously issued from archived templates will remain
            available in your dashboard.
          </Text>
        </VStack>
      </HStack>
      <Box w={'100%'}>
        {templates.length === 0 ? (
          // users should not see this
          <Center>No archived templates</Center>
        ) : (
          <TemplatesBody templates={templates} isArchived />
        )}
      </Box>
      <Box w={'100%'} justifyContent={'right'} pt="30px">
        {count && count > 0 ? (
          <Box float={'right'}>
            <Pagination
              totalCount={count}
              pageSize={PAGE_SIZE}
              onPageChange={(newPage) => {
                setSearchParams((params) => {
                  if (newPage == 1) return {}
                  params.set('currentPage', newPage.toString())
                  return params
                })
                window.scrollTo(0, 0)
              }}
              currentPage={currentPage + 1}
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </VStack>
  )
}
