import { Avatar, Button, HStack, Text } from '@chakra-ui/react'
import { BiTrash } from 'react-icons/bi'

interface CollaboratorRowProps {
  userEmail: string
  onClickRemove?: () => void
  removeDisabled?: boolean
}
export const CollaboratorRow = ({
  userEmail,
  onClickRemove,
  removeDisabled,
}: CollaboratorRowProps) => {
  return (
    <HStack
      justify={'space-between'}
      _hover={{
        backgroundColor: 'grey.50',
        borderRadius: '2px',
      }}
    >
      <HStack>
        <Avatar
          w="30px"
          h="30px"
          size={'sm'}
          name={userEmail}
          getInitials={(collaborator: string) => collaborator.charAt(0)}
          bg={'blue.300'}
        />
        <Text fontSize={'14px'}>{userEmail}</Text>
      </HStack>
      <Button
        onClick={onClickRemove}
        variant={'outline'}
        color="red.500"
        p={0}
        m={0}
        isDisabled={removeDisabled}
        border="none"
        aria-label="Remove collaborator"
        _hover={{ color: 'blue.500' }}
        _disabled={{ color: 'grey.200', cursor: 'auto' }}
      >
        <BiTrash size="1rem" />
      </Button>
    </HStack>
  )
}
