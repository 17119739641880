import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { BiBlock, BiIdCard, BiInfoCircle, BiQrScan } from 'react-icons/bi'

export const LetterInfoCard = (): JSX.Element => {
  return (
    <Accordion allowToggle>
      <AccordionItem fontSize={'12px'} textStyle="legal">
        <AccordionButton pl={0}>
          <Box px={'24px'}>
            <HStack spacing={3}>
              <BiInfoCircle />
              <Text
                align={'start'}
                fontSize={'12px'}
                fontWeight={'400'}
                textColor="grey.700"
              >
                Learn more about digital letters from letters.gov.sg
              </Text>
            </HStack>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel padding="0px" backgroundColor={'white'}>
          <hr />

          <Box padding="24px">
            <HStack paddingBottom={'5px'} align="top">
              <BiIdCard size="1.6rem" />
              <VStack align={'left'}>
                <Text fontWeight={'700'}>
                  No request for personal information
                </Text>
                <Text paddingBottom="16px">
                  Letters.gov.sg will not request any personal information like
                  credit card details, bank account details, or OTPs
                </Text>
              </VStack>
            </HStack>

            <HStack paddingBottom={'5px'} top="0px" align="top">
              <BiQrScan size="1.6rem" />
              <VStack align={'left'}>
                <Text fontWeight={'700'}>Letters are permanent</Text>
                <Text paddingBottom="16px">
                  Letters issued through letters.gov.sg will be accessible at
                  all times and will not expire
                </Text>
              </VStack>
            </HStack>

            <HStack paddingBottom={'5px'} top="0px" align="top">
              <BiBlock size="1.6rem" />{' '}
              <VStack align={'left'}>
                <Text fontWeight={'700'}>Forgery is an offense</Text>
                <Text>
                  Any attempts to copy, forge, or morph letter(s) on
                  letters.gov.sg is a criminal offense
                </Text>
              </VStack>
            </HStack>
          </Box>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
