import { Box, HStack, Image, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { useFeatureValue } from '@growthbook/growthbook-react'

import { useIsDesktop } from '~hooks/useIsDesktop'
import { GrowthBookFeatures } from '~shared/types/feature-flag'

import { desktopFormat, mobileFormat } from '../styles'

const agencyLogos = [
  'https://file.go.gov.sg/logos/ica-new.png',
  'https://file.go.gov.sg/logos/moe.png',
  'https://file.go.gov.sg/logos/pa.jpg',
  'https://file.go.gov.sg/logos/hdb.png',
  'https://file.go.gov.sg/logos/scdf.png',
  'https://file.go.gov.sg/logos/ecda.png',
  'https://file.go.gov.sg/logos/mindef.png',
]

interface LandingPageStatisticProps {
  title: string
  count: string
}

export const StatisticsSection = () => {
  const isDesktop = useIsDesktop()
  const format = isDesktop ? desktopFormat : mobileFormat
  const getLogoHeight = (agency: string) =>
    agency === 'hdb.png' || agency === 'scdf.png' ? '70%' : 'full'
  const landingPageStatistics = useFeatureValue(
    GrowthBookFeatures.landingPageStatistics,
    [
      { title: 'Letters sent', count: '0' },
      { title: 'Templates supported', count: '0' },
      { title: 'Public agencies', count: '0' },
    ],
  ) as LandingPageStatisticProps[]
  return (
    <VStack>
      <Text
        textStyle={isDesktop ? 'h1' : 'h3'}
        textAlign={'center'}
        pb={isDesktop ? 12 : 6}
      >
        Trusted by agencies across the Government
      </Text>
      {isDesktop ? (
        <>
          <SimpleGrid columns={3} spacing={10} w={'70%'} pb={14}>
            {landingPageStatistics.map((statistic) => (
              <VStack key={statistic.title}>
                <Text textStyle={format.heading} color={'base.content.brand'}>
                  {statistic.count}
                </Text>
                <Text textStyle={format.subHeading}>{statistic.title}</Text>
              </VStack>
            ))}
          </SimpleGrid>
          <HStack h={'4.5rem'} w={'full'} justify={'space-between'} spacing={4}>
            {agencyLogos.map((logo) => {
              const agencyName = logo.split('/').slice(-1)[0]
              return (
                <Image
                  h={getLogoHeight(agencyName)}
                  src={logo}
                  key={agencyName}
                />
              )
            })}
          </HStack>
        </>
      ) : (
        <StatisticsMobileView
          format={format}
          statistics={landingPageStatistics}
        />
      )}
    </VStack>
  )
}

const StatisticsMobileView = ({
  format,
  statistics,
}: {
  format: { [key: string]: string }
  statistics: LandingPageStatisticProps[]
}) => {
  return (
    <VStack w={'full'} spacing={5} maxW={'500px'}>
      <HStack justify={'space-around'} w={'full'}>
        <VStack>
          <Text textStyle={format.heading} color={'base.content.brand'}>
            {statistics[0].count}
          </Text>
          <Text textStyle={format.subHeading}>{statistics[0].title}</Text>
        </VStack>
        <VStack>
          <Text textStyle={format.heading} color={'base.content.brand'}>
            {statistics[1].count}
          </Text>
          <Text textStyle={format.subHeading}>{statistics[1].title}</Text>
        </VStack>
      </HStack>
      <Box textAlign={'center'}>
        <Text textStyle={format.heading} color={'base.content.brand'}>
          {statistics[2].count}
        </Text>
        <Text textStyle={format.subHeading}>{statistics[2].title}</Text>
      </Box>
      <HStack justify={'space-around'} w={'full'} pt={10}>
        <Image h={'4rem'} src={agencyLogos[0]} />
        <Image h={'4rem'} src={agencyLogos[1]} />
        <Image h={'4rem'} src={agencyLogos[2]} />
      </HStack>
      <HStack justify={'space-around'} w={'full'} pt={4}>
        <Image h={'3rem'} src={agencyLogos[3]} />
        <Image h={'3rem'} src={agencyLogos[4]} />
      </HStack>
      <HStack justify={'space-around'} w={'full'} pt={4} px={10}>
        <Image h={'4rem'} src={agencyLogos[5]} />
        <Image h={'4rem'} src={agencyLogos[6]} />
      </HStack>
    </VStack>
  )
}
