import {
  Avatar,
  Button,
  Divider,
  Hide,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuList,
  Show,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { useEffect, useState } from 'react'
import {
  BiAddToQueue,
  BiCommentDots,
  BiHelpCircle,
  BiHomeAlt,
  BiLogOutCircle,
  BiNews,
} from 'react-icons/bi'
import { useLocation, useNavigate } from 'react-router-dom'

import { LAST_SEEN_FEEDBACK_TIMESTAMP } from '~constants/localStorage'
import { useAdminAuth } from '~features/auth/context/AdminProtectedContext'
import { SendFeedbackModal } from '~features/feedback/components/SendFeedbackModal'
import { USER_GUIDE } from '~shared/constants/links'
import { GrowthBookFeatures } from '~shared/types/feature-flag'

import { NavMenuItem } from './NavMenuItem'

interface LocationState {
  state?: {
    shouldOpenFeedback?: boolean
  }
}

export const UserMenu = () => {
  const { adminUser, logout } = useAdminAuth()
  const {
    isOpen: isOpenFeedback,
    onOpen: onOpenFeedback,
    onClose: onCloseFeedback,
  } = useDisclosure()
  const { state } = useLocation() as LocationState
  const shouldOpenFeedback = state?.shouldOpenFeedback
  const [currentUrl, setCurrentUrl] = useState<string>()

  useEffect(function getCurrentUrl() {
    setCurrentUrl(window.location.href)
  }, [])

  // useEffect to open feedback modal if completion of other actions trigger it
  useEffect(() => {
    if (shouldOpenFeedback) {
      const feedbackLastSeen = localStorage.getItem(
        LAST_SEEN_FEEDBACK_TIMESTAMP,
      )
      if (!feedbackLastSeen) {
        // store timestamp so that we can fine-tune the logic for displaying in the future if we want
        localStorage.setItem(
          LAST_SEEN_FEEDBACK_TIMESTAMP,
          new Date().toISOString(),
        )
        setTimeout(() => {
          onOpenFeedback()
        }, 1000)
      }
    }
  }, [shouldOpenFeedback])

  const navigate = useNavigate()

  return (
    <Menu isLazy>
      {() => (
        <>
          <HStack spacing={{ md: 6, base: 2 }} textColor={'grey.300'}>
            <Button
              size="sm"
              variant="ghost"
              border="none"
              onClick={() => navigate('/admin/letters')}
              _hover={{ textColor: 'white' }}
              color={
                currentUrl?.includes('/admin/letters') ?? false
                  ? 'standard.white'
                  : 'grey.300'
              }
            >
              <Hide below="md">Dashboard</Hide>
              <Show below="md">
                <BiHomeAlt size={20} />
              </Show>
            </Button>
            <Button
              size="sm"
              variant="ghost"
              border="none"
              onClick={() => navigate('/admin/templates')}
              _hover={{ textColor: 'standard.white' }}
              color={
                currentUrl?.includes('/admin/templates') ?? false
                  ? 'standard.white'
                  : 'grey.300'
              }
            >
              <Hide below="md">Template Library</Hide>
              <Show below="md">
                <BiNews size={20} />
              </Show>
            </Button>
            <Button
              size="sm"
              variant="ghost"
              border="none"
              onClick={() => navigate('/admin/api-integration')}
              _hover={{ textColor: 'standard.white' }}
              color={
                currentUrl?.includes('/admin/api-integration') ?? false
                  ? 'standard.white'
                  : 'grey.300'
              }
            >
              <Hide below="md">API Integration</Hide>
              <Show below="md">
                <BiAddToQueue size={20} />
              </Show>
            </Button>
            <Divider orientation="vertical" h="5" borderColor={'grey.300'} />
            <Button
              size="sm"
              variant="ghost"
              border="none"
              onClick={onOpenFeedback}
              _hover={{ textColor: 'standard.white' }}
            >
              <Hide below="md">
                <Text>Share Feedback</Text>
              </Hide>
              <Show below="md">
                <BiCommentDots size={20} />
              </Show>
            </Button>
            <Link
              href={USER_GUIDE}
              isExternal
              textColor={'grey.300'}
              _hover={{ textColor: 'standard.white' }}
              textDecoration="none"
            >
              <Button size="sm" variant="ghost" border="none">
                <Hide below="md">Get Help</Hide>
                <Show below="md">
                  <BiHelpCircle size={20} />
                </Show>
              </Button>
            </Link>
            <MenuButton name="menu-button" pl={2}>
              <Avatar
                w="30px"
                h="30px"
                name={adminUser?.email}
                getInitials={(name) => name.charAt(0)}
              />
            </MenuButton>
          </HStack>

          <MenuList py={0} fontSize="sm">
            <NavMenuItem closeOnSelect={false}>
              <Text userSelect="text">{adminUser?.email}</Text>
            </NavMenuItem>
            <Divider />
            <NavMenuItem
              icon={BiLogOutCircle}
              onClick={() => logout()}
              border={'5px solid'}
              borderColor={'standard.white'}
            >
              Logout
            </NavMenuItem>
          </MenuList>
          <SendFeedbackModal
            isOpen={isOpenFeedback}
            onClose={onCloseFeedback}
          />
        </>
      )}
    </Menu>
  )
}
