import { Box, Image, Text, VStack } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import TemplatePreviewFallback from '~/assets/TemplatePreviewFallback.svg'
import { useIsDesktop } from '~/hooks/useIsDesktop'

import { LandingPageSampleUseCaseProps } from '../sections/SampleUseCaseSection'
import { desktopFormat, mobileFormat } from '../styles'

export const LetterCard = ({
  name,
  agency,
  publicId,
  thumbnailUrl,
}: LandingPageSampleUseCaseProps) => {
  const isDesktop = useIsDesktop()
  const format = isDesktop ? desktopFormat : mobileFormat
  return (
    <VStack spacing={5}>
      <Box position="relative" w={'100%'}>
        <RouterLink to={publicId} target="_blank">
          <Image
            src={thumbnailUrl}
            fit="cover"
            objectPosition="center top"
            fallbackSrc={TemplatePreviewFallback}
            borderRadius="4px"
            shadow="sm"
            aspectRatio="0.71"
            _hover={{
              cursor: 'pointer',
              border: '2px solid',
              borderColor: 'interaction.main-subtle.active',
              shadow: 'md',
            }}
            width={'100%'}
            height={'100%'}
          />
        </RouterLink>
        <Text
          position="absolute"
          pointerEvents="none"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%) rotate(-30deg)"
          textColor="base.content.medium"
          fontWeight="700"
          fontSize={`${isDesktop ? 3 : 6}vw`} // font size should be inversely proportional to the number of letter card columns
          opacity="0.5"
        >
          SAMPLE
        </Text>
      </Box>
      <VStack spacing="4px" width="100%">
        <Text
          noOfLines={2}
          textStyle={format.subHeading}
          textAlign={'center'}
          pb={1}
          px={3}
          fontWeight={700}
        >
          {name}
        </Text>
        <Text noOfLines={2} textStyle={format.body} textAlign={'center'} px={3}>
          {agency}
        </Text>
      </VStack>
    </VStack>
  )
}
