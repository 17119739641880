import { useQuery } from '@tanstack/react-query'

import { adminQueryKeys } from '~constants/query-keys'
import { api } from '~lib/api'
import { GetGovSgMetadataDto } from '~shared/dtos/agency-metadata.dto'

export const useGovSgMetadata = ({ enabled = false }: { enabled: boolean }) => {
  const { data } = useQuery(
    [adminQueryKeys.govSgMetadata],
    () => {
      return api.url(`/agency-metadata/govsg`).get().json<GetGovSgMetadataDto>()
    },
    { enabled },
  )

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return { govSgMetadata: data }
}
