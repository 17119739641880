import { useEffect, useState } from 'react'

import { WIDTH_A4 } from '~utils/htmlUtils'

export const calculateTransformScale = (
  viewportWidth: number,
  transformScaleMultiplier = 0.9,
): number =>
  // For viewports with width smaller than A4, we want the letter to take up 90% of the viewport
  Math.min(viewportWidth * transformScaleMultiplier, WIDTH_A4) / WIDTH_A4

export const useTransformScale = () => {
  const [transformScale, setTransformScale] = useState<number>(
    calculateTransformScale(window.innerWidth),
  )

  useEffect(() => {
    // Calculate the transform scale once on component mount
    const scale = calculateTransformScale(window.innerWidth)
    setTransformScale(scale)
  }, []) // Empty dependency array ensures this effect runs only once on mount

  return transformScale
}
