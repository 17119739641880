import { Badge, Box, Text, VStack } from '@chakra-ui/react'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { Tag } from '@opengovsg/design-system-react'

import { GetTemplateDto } from '~shared/dtos/templates.dto'
import { GrowthBookFeatures } from '~shared/types/feature-flag'
import { getLocaleTimeFromDate, parseDateV2 } from '~utils/dateUtils'

import { TemplatePreviewThumbnail } from './thumbnail/TemplatePreviewThumbnail'

type TemplateCardProps = {
  template: GetTemplateDto
  index: number
  onClick: (index: number) => void
}

export const TemplateCard = ({
  template,
  index,
  onClick,
}: TemplateCardProps) => {
  const sampleTemplateId = useFeatureValue(
    GrowthBookFeatures.sampleTemplateId,
    0,
  )

  const timeInAmAndPm = getLocaleTimeFromDate(template.updatedAt)
  const date = parseDateV2(template.updatedAt)
  const timeToShow = `Edited ${date}, ${timeInAmAndPm}`

  return (
    <VStack align={'left'}>
      <TemplatePreviewThumbnail
        html={template.html}
        align={'center'}
        aspectRatio={'0.71'}
        borderRadius={'4px'}
        onClick={() => onClick(index)}
        _hover={{
          cursor: 'pointer',
          border: '2px solid',
          borderColor: 'interaction.main.default',
          shadow: 'md',
        }}
        bg="white"
        shadow={'sm'}
      />
      <Box>
        {/* Use Box to control the margin inside, since we are using VStack */}
        <Text noOfLines={2} mt="20px" textStyle={'subhead-1'}>
          {template.name}
        </Text>
        {template.id !== sampleTemplateId && (
          <Text
            noOfLines={2}
            mt="4px"
            textStyle={'subhead-2'}
            textColor={'grey.500'}
          >
            {timeToShow}
          </Text>
        )}
        {template.id === sampleTemplateId && (
          <Badge variant="subtle" borderRadius={'xl'} width={'fit-content'}>
            Sample template
          </Badge>
        )}
      </Box>
    </VStack>
  )
}
