import { Box, useBreakpointValue, useDisclosure } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'

import { CITIZEN_LETTER_FEEDBACK_SHOW_STATUS } from '~constants/localStorage'
import { useSendCitizenFeedbackMutation } from '~features/feedback/hooks/feedback.hooks'

import {
  FeedbackFormData,
  LocalStorageCitizenFeedbackData,
} from './constants/feedback'
import OpinionBox from './OpinionBox'
import ReasonBox from './ReasonBox'
import ThankYouBox from './ThankYouBox'

interface FeedbackFormProps {
  letterId: string
  onClose: () => void
}

const MotionBox = motion(Box)

const onFormDone = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const storedData: LocalStorageCitizenFeedbackData | null = JSON.parse(
    localStorage.getItem(CITIZEN_LETTER_FEEDBACK_SHOW_STATUS) || '{}',
  )
  localStorage.setItem(
    CITIZEN_LETTER_FEEDBACK_SHOW_STATUS,
    JSON.stringify({
      timesVisited: storedData?.timesVisited || 0,
      isFormDone: true,
      lastShownTime: storedData?.lastShownTime || '',
    }),
  )
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({
  letterId,
  onClose,
}: FeedbackFormProps) => {
  const [isGood, setIsGood] = useState(false)

  const { mutateAsync, isLoading } = useSendCitizenFeedbackMutation()

  const onSubmit = async (data: FeedbackFormData) => {
    await mutateAsync({
      letterPublicId: letterId,
      citizenFeedbackTitle: data.citizenFeedbackTitle,
      citizenFeedbackText: data.citizenFeedbackText,
      citizenFeedbackFreeText: data.citizenFeedbackFreeText
        ? data.citizenFeedbackFreeText
        : 'NIL',
    })
  }

  const {
    isOpen: isShowOpinionBoxOpen,
    onOpen: onShowOpinionBoxOpen,
    onClose: onShowOpinionBoxClose,
  } = useDisclosure({ defaultIsOpen: true })

  const {
    isOpen: isShowReasonBoxOpen,
    onOpen: onShowReasonBoxOpen,
    onClose: onShowReasonBoxClose,
  } = useDisclosure()

  const {
    isOpen: isShowThankYouBoxOpen,
    onOpen: onShowThankYouBoxOpen,
    onClose: onShowThankYouBoxClose,
  } = useDisclosure()

  const dynamicWidth = isShowThankYouBoxOpen ? '350px' : '508px'
  const dynamicPadding = isShowThankYouBoxOpen ? 4 : 8
  const dynamicPaddingBottom = isShowThankYouBoxOpen ? 4 : 5

  const boxPosition = useBreakpointValue({
    base: {
      position: 'fixed',
      bottom: 0,
      pt: 6,
      pr: 6,
      pb: 4,
      pl: 6,
      width: '100%',
      borderTopRadius: '20px', // Rounded corners on top for mobile
      bg: 'white',
    },
    md: {
      position: 'fixed',
      pt: dynamicPadding,
      pr: dynamicPadding,
      pl: dynamicPadding,
      pb: dynamicPaddingBottom,
      bottom: '20px', // Offset from the bottom for larger screens
      right: '20px', // Offset from the right for larger screens
      borderRadius: 'lg', // Rounded corners for larger screens
      bg: 'white',
      width: dynamicWidth, // Dynamic width based on the open box
    },
  })

  const showReasonBoxAndHideOpinionBox = () => {
    onShowOpinionBoxClose()
    onShowReasonBoxOpen()
  }

  const closeReasonBox = () => {
    onShowReasonBoxClose()
    onClose()
  }

  const closeOpinionBox = () => {
    onShowOpinionBoxClose()
    onClose()
  }

  const closeThankYouBox = () => {
    onShowThankYouBoxClose()
    onClose()
  }

  const showThankYouBoxAndHideReasonBox = () => {
    onShowReasonBoxClose()
    onShowThankYouBoxOpen()
  }

  return (
    <MotionBox
      {...boxPosition}
      display="flex"
      zIndex="1"
      boxShadow="md"
      // Animation to do the bouncing motion
      initial={{ opacity: 0, y: '20px' }} // Start slightly above the final position
      animate={{
        opacity: 1,
        y: ['20px', '-10px', '0px'], // Move up higher then settle
      }}
      transition={{
        duration: 0.5,
        ease: 'easeInOut', // Smooth easing
      }}
    >
      {isShowOpinionBoxOpen && (
        <OpinionBox
          goToNext={showReasonBoxAndHideOpinionBox}
          onClose={closeOpinionBox}
          setIsGood={setIsGood}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
      )}
      {isShowReasonBoxOpen && (
        <ReasonBox
          isGood={isGood}
          goToNext={showThankYouBoxAndHideReasonBox}
          onFormDone={onFormDone}
          onClose={closeReasonBox}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
      )}
      {isShowThankYouBoxOpen && <ThankYouBox onClose={closeThankYouBox} />}
    </MotionBox>
  )
}

export default FeedbackForm
