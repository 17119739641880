import { Box, CircularProgress, Text, VStack } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface EmptyPreviewProps {
  title?: string
  subtitle?: ReactNode
  isLoading?: boolean
}
export const EmptyPreview = ({
  title,
  subtitle,
  isLoading = false,
}: EmptyPreviewProps) => {
  return (
    <VStack
      height={'100%'}
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      spacing="10"
    >
      <Box
        height={'100%'}
        width="80%"
        backgroundColor="grey.50"
        margin="60px"
        marginBottom="0px"
        marginTop={'60px'}
        display="flex"
        borderTopRadius={'10px'}
        justifyContent="center"
        alignItems="center"
        boxShadow={'0px 0px 10px 3px rgba(0, 0, 0, 0.1)'}
        minWidth={750}
      >
        <VStack w={'65%'} pt="15%">
          {isLoading ? (
            <CircularProgress
              isIndeterminate
              color="brand.primary.500"
              size={'50px'}
            />
          ) : (
            <>
              <Text textStyle={'h3'} color="grey.300">
                {title}
              </Text>
              <Text textStyle="body-4" color="grey.300">
                {subtitle}
              </Text>
            </>
          )}
        </VStack>
      </Box>
    </VStack>
  )
}
