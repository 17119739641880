import { Box, HStack, Link, Text, VStack } from '@chakra-ui/react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { Button, Pagination } from '@opengovsg/design-system-react'
import { useMemo } from 'react'
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import { Loading } from '~components/Loading'
import { routes } from '~constants/routes'
import { useGetTemplates } from '~features/dashboard/hooks/dashboard.hooks'
import { REQUEST_NEW_TEMPLATE } from '~shared/constants/links'
import { GrowthBookFeatures } from '~shared/types/feature-flag'

import { EmptyTemplatesBody } from './components/EmptyTemplatesBody'
import { TemplatesBody } from './components/TemplatesBody'

export const PAGE_SIZE = 16
const DEFAULT_PAGE_SEARCH_PARAM = '1'

export const TemplatesPage = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams()
  const currentPage = useMemo(
    () =>
      parseInt(searchParams.get('currentPage') || DEFAULT_PAGE_SEARCH_PARAM) -
      1,
    [searchParams],
  )

  const isTemplateCreateOn = useFeatureIsOn(
    GrowthBookFeatures.templateCreate as string,
  )
  const navigate = useNavigate()

  const templatesPageSize =
    // if template create is on, first page is reduced by 1 because of create new button
    isTemplateCreateOn && currentPage === 0 ? PAGE_SIZE - 1 : PAGE_SIZE
  const templatesOffset =
    // if template create is on, offset is reduced by 1 because of create new button on first page, with min of 0
    isTemplateCreateOn
      ? Math.max(currentPage * templatesPageSize - 1, 0)
      : currentPage * templatesPageSize

  const { templates, isTemplatesLoading, count } = useGetTemplates({
    limit: templatesPageSize,
    offset: templatesOffset,
  })

  const { count: archivedCount } = useGetTemplates({
    limit: PAGE_SIZE,
    offset: currentPage * PAGE_SIZE,
    isArchived: true,
  })

  const buttonText = isTemplateCreateOn
    ? `Create new template`
    : `Request template`
  const handleOnClick = () => {
    if (isTemplateCreateOn) {
      navigate(
        `/${routes.admin.index}/${routes.admin.templates.index}/${routes.admin.templates.create}`,
      )
    } else {
      window.open(REQUEST_NEW_TEMPLATE)
    }
  }

  if (isTemplatesLoading) {
    return <Loading fullscreen={true} />
  }

  return templates.length === 0 ? (
    <EmptyTemplatesBody buttonText={buttonText} handleOnClick={handleOnClick} />
  ) : (
    <VStack py={'50px'} align={'start'}>
      <HStack
        w={'100%'}
        mb={'15px'}
        paddingBottom={'36px'}
        justifyContent="space-between"
      >
        <VStack align={'left'}>
          <Text textStyle={'h3'}>Template Library</Text>
          <Text>Browse templates to create letters easily</Text>
        </VStack>
        {/* request button only appears if template create is not enabled */}
        {!isTemplateCreateOn && (
          <Button bg="interaction.main.default" onClick={handleOnClick}>
            {buttonText}
          </Button>
        )}
      </HStack>

      <Box w={'100%'}>
        <TemplatesBody
          templates={templates}
          showCreate={currentPage === 0 && isTemplateCreateOn}
        />
      </Box>
      <Box w={'100%'} justifyContent={'right'} pt="30px">
        {count && (
          <Box float={'right'}>
            <HStack spacing={3}>
              {archivedCount && archivedCount > 0 && (
                <Link as={RouterLink} textDecoration="none" to={`archives`}>
                  See archived templates
                </Link>
              )}
              <Pagination
                // if template create is on, count is increased by 1 to account for offset from create button
                totalCount={isTemplateCreateOn ? count + 1 : count}
                pageSize={PAGE_SIZE}
                onPageChange={(newPage) => {
                  setSearchParams((params) => {
                    if (newPage == 1) return {}
                    params.set('currentPage', newPage.toString())
                    return params
                  })
                  window.scrollTo(0, 0)
                }}
                currentPage={currentPage + 1}
              />
            </HStack>
          </Box>
        )}
      </Box>
    </VStack>
  )
}
