import { UseToastOptions } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { useRef } from 'react'
import { WretchError } from 'wretch/types'

import { useToast } from '~hooks/useToast'
import { api } from '~lib/api'
import {
  IMAGE_UPLOAD_SIZE_TOO_LARGE_TEXT,
  MAX_TEMPLATE_IMAGE_SIZE_BYTES,
} from '~shared/constants/template-images'
import { UploadImageResponseDto } from '~shared/dtos/templates.dto'

export const useUploadImage = () => {
  const toast = useToast()
  const toastIdRef = useRef<string | number>()

  const { mutateAsync } = useMutation(
    async (image: File): Promise<UploadImageResponseDto> => {
      if (image.size > MAX_TEMPLATE_IMAGE_SIZE_BYTES) {
        throw new Error(IMAGE_UPLOAD_SIZE_TOO_LARGE_TEXT)
      }
      toastIdRef.current = toast({
        title: 'Image is being uploaded, this may take a few seconds',
        status: 'loading',
        colorScheme: 'warning',
      })

      return await api
        .url('/templates/images')
        .formData({ image })
        .post()
        .json<UploadImageResponseDto>()
    },
    {
      onError: (err: WretchError) => {
        if (err.message === IMAGE_UPLOAD_SIZE_TOO_LARGE_TEXT) {
          return toast({
            title: err.message,
            status: 'info',
          })
        }
        const errorToast: UseToastOptions = {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          title: err.json?.message as string,
          status: 'error',
        }
        if (toastIdRef.current) {
          toast.update(toastIdRef.current, errorToast)
        } else {
          toastIdRef.current = toast(errorToast)
        }
      },
      onSuccess: (data) => {
        if (!data || !data.url) {
          const infoToast: UseToastOptions = {
            title: 'File uploads are not supported',
            status: 'info',
          }
          if (toastIdRef.current) {
            toast.update(toastIdRef.current, infoToast)
          } else {
            toastIdRef.current = toast(infoToast)
          }
        }
      },
    },
  )

  return mutateAsync
}
