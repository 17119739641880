import {
  CircularProgress,
  HStack,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'
import Lottie from 'lottie-react'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { BiCheckCircle } from 'react-icons/bi'

import CelebrationConfetti from '~/assets/CelebrationConfetti.json'
import LetterConfirmation from '~/assets/LetterConfirmation.svg'
import { CitizenNotificationMethod } from '~shared/dtos/letters.dto'

interface IssueSuccessModalProps {
  isOpen: boolean
  onClose: () => void
}

export const IssueSuccessModal = ({
  isOpen,
  onClose,
}: IssueSuccessModalProps) => {
  const { getValues } = useFormContext()
  const citizenNotificationMethod = getValues(
    'citizenNotificationMethod',
  ) as CitizenNotificationMethod

  useEffect(function startIssueLetterSimulation() {
    isOpen && simulateIssueLetterProcess()
  })

  const [stepOne, setStepOne] = useState(false)
  const [stepTwo, setStepTwo] = useState(false)
  const [stepThree, setStepThree] = useState(false)

  const getRandomTiming = () => Math.random() * 500 + 500

  const simulateIssueLetterProcess = () => {
    setTimeout(() => {
      setStepOne(true)
      setTimeout(() => {
        setStepTwo(true)
        setTimeout(() => {
          setStepThree(true)
        }, getRandomTiming())
      }, getRandomTiming())
    }, getRandomTiming())
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton onClick={onClose} />
        <div
          style={{
            position: 'relative',
            alignSelf: 'center',
            maxWidth: '1000px ',
          }}
        >
          <ModalCloseButton onClick={onClose} />
          <Image
            alignSelf="center"
            src={LetterConfirmation}
            borderTopRadius={'8px'}
          />
          <Lottie
            animationData={CelebrationConfetti}
            loop={true}
            style={{
              position: 'absolute',
              top: '-50%',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '80%',
              zIndex: '1',
            }}
          />
        </div>
        <VStack
          px="32px"
          pb="32px"
          alignItems={'left'}
          pt="10px"
          fontSize="18px"
        >
          <VStack pl="0" pt="5" align="left">
            <HStack>
              {stepOne ? (
                <BiCheckCircle color="green" size={'25px'} />
              ) : (
                <CircularProgress
                  isIndeterminate
                  color="brand.primary.500"
                  size={'25px'}
                />
              )}
              <Text>
                {citizenNotificationMethod == CitizenNotificationMethod.NONE
                  ? 'Creating a CSV of your letters'
                  : `Generating letters`}
              </Text>
            </HStack>
            <HStack>
              {stepTwo ? (
                <BiCheckCircle color="green" size={'25px'} />
              ) : (
                <CircularProgress
                  isIndeterminate
                  color="brand.primary.500"
                  size={'25px'}
                />
              )}
              <Text>
                {citizenNotificationMethod == CitizenNotificationMethod.NONE
                  ? 'Downloading CSV'
                  : `Sending letters`}
              </Text>
            </HStack>
            <HStack>
              {stepThree ? (
                <BiCheckCircle color="green" size={'25px'} />
              ) : (
                <CircularProgress
                  isIndeterminate
                  color="brand.primary.500"
                  size={'25px'}
                />
              )}
              <Text>
                {citizenNotificationMethod == CitizenNotificationMethod.NONE
                  ? 'CSV downloaded'
                  : 'Letters sent'}
              </Text>
            </HStack>
          </VStack>
        </VStack>
        <ModalFooter pt="0" px="32px" pb="32px">
          <Button onClick={onClose}>View report</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
