export const parseDate = (date: string): string => {
  const dateObj = new Date(date)
  const dateDetails = dateObj.toDateString().split(' ')
  const formattedDate = dateDetails[2] + ' ' + dateDetails[1]
  return formattedDate
}

// Type 2 Output: 1 Oct 2023
export const parseDateV2 = (date: string, fullYear = true): string => {
  const dateObj = new Date(date)
  const dateDetails = dateObj.toDateString().split(' ')
  const formattedDate =
    dateDetails[2] +
    ' ' +
    dateDetails[1] +
    ' ' +
    dateDetails[3].slice(fullYear ? 0 : 2)
  return formattedDate
}

// Type 2 Output: Sun, 1 Oct 2023
export const parseDateV2WithDay = (date: string, fullYear = true): string => {
  const dateObj = new Date(date)
  const dateDetails = dateObj.toDateString().split(' ')
  const formattedDate =
    dateDetails[0] +
    ', ' +
    dateDetails[2] +
    ' ' +
    dateDetails[1] +
    ' ' +
    dateDetails[3].slice(fullYear ? 0 : 2)
  return formattedDate
}

// Output: 11.59 PM / 1.02 AM
export const getLocaleTimeFromDate = (date: string): string => {
  const dateObj = new Date(date)
  const formattedTime = dateObj
    .toLocaleTimeString([], {
      hour: 'numeric',
      minute: '2-digit',
      hourCycle: 'h12',
    })
    .toUpperCase()
  return formattedTime
}
