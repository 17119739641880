import { Navigate, Outlet, RouteObject } from 'react-router-dom'

import { routes } from '~/constants/routes'
import { AdminLayout } from '~/layouts/AdminLayout'
import { ApiIntegrationPage } from '~features/api/ApiIntegrationPage'
import { AdminProtectedRoute } from '~features/auth/context/AdminProtectedRoute'
import { LoginPage } from '~features/auth/LoginPage'
import { CreateTemplatePage } from '~features/create/CreateTemplatePage'
import { SelectTemplateLayoutPage } from '~features/create/SelectTemplateLayoutPage'
import { DashboardPage } from '~features/dashboard/DashboardPage'
import { IssuedBatchView } from '~features/dashboard/IssuedBatchView'
import { EditTemplatePage } from '~features/editor/EditTemplatePage'
import { BulkIssuePage } from '~features/issue/BulkIssuePage'
import { ArchivedTemplatesPage } from '~features/template-library/ArchivedTemplatesPage'
import { TemplatesPage } from '~features/template-library/TemplatesPage'

export const adminRoutes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to={routes.admin.templates.index} />,
  },
  {
    path: routes.admin.login,
    element: <LoginPage />,
  },
  {
    path: routes.admin.templates.index,
    children: [
      {
        element: (
          <AdminProtectedRoute>
            <AdminLayout />
          </AdminProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <TemplatesPage />,
          },
        ],
      },
      {
        path: routes.admin.templates.archive,
        element: (
          <AdminProtectedRoute>
            <AdminLayout />
          </AdminProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <ArchivedTemplatesPage />,
          },
        ],
      },
      {
        path: routes.admin.templates.create,
        element: (
          <AdminProtectedRoute>
            <SelectTemplateLayoutPage />
          </AdminProtectedRoute>
        ),
      },
      {
        path: routes.admin.templates.edit,
        element: (
          <AdminProtectedRoute>
            <CreateTemplatePage />
          </AdminProtectedRoute>
        ),
      },
      {
        path: `${routes.admin.templates.edit}/:templateId`,
        element: (
          <AdminProtectedRoute>
            <EditTemplatePage />
          </AdminProtectedRoute>
        ),
      },
    ],
  },
  {
    path: routes.admin.letters,
    element: (
      <AdminProtectedRoute>
        <AdminLayout />
      </AdminProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <DashboardPage />,
      },
    ],
  },
  {
    path: routes.admin.api,
    element: (
      <AdminProtectedRoute>
        <ApiIntegrationPage />
      </AdminProtectedRoute>
    ),
  },
  {
    path: `${routes.admin.templates.index}/issue`,
    element: (
      <AdminProtectedRoute>
        <BulkIssuePage />
      </AdminProtectedRoute>
    ),
  },
  {
    path: `${routes.admin.batches}/:batchId`,
    element: (
      <AdminProtectedRoute>
        <AdminLayout />
      </AdminProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <IssuedBatchView />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={routes.admin.templates.index} />,
  },
]
