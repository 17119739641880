import { Box, Button, Flex, Image, Stack, Text } from '@chakra-ui/react'
import { BiRightArrowAlt } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

import ELettersSvg from '~/assets/ELetters.svg'
import { useIsDesktop } from '~hooks/useIsDesktop'

export const IntroductionSection = ({
  sampleUseCaseSectionOffset,
  isGsibUser,
}: {
  sampleUseCaseSectionOffset: number | undefined
  isGsibUser: boolean
}) => {
  const isDesktop = useIsDesktop()
  const navigate = useNavigate()
  const scrollToSampleLetter = () => {
    window.scrollTo({
      top: sampleUseCaseSectionOffset,
      behavior: 'smooth',
    })
  }
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      align="left"
      spacing={{ base: '1.5rem', md: '3.125rem', lg: '4.125rem' }}
    >
      <Flex flexDir="column" flex={1}>
        <Text
          textStyle={{
            base: 'responsive-display.heavy',
            md: 'responsive-display.heavy-480',
            lg: 'responsive-display.heavy-480',
          }}
          pt={{ base: 0, md: '1rem' }}
          pb="2rem"
        >
          Trusted e-letters from the Government
        </Text>
        <Text>
          LetterSG enables Singapore Government agencies to easily create and
          send official, personalised e-letters and certifications to members of
          the public via letters.gov.sg links.
        </Text>
        <Box>
          {isGsibUser ? (
            <Button
              w={isDesktop ? 'unset' : 'full'}
              mt="2.5rem"
              rightIcon={<BiRightArrowAlt />}
              onClick={() => navigate('/admin/login')}
            >
              Start creating a letter
            </Button>
          ) : (
            <Button
              w={isDesktop ? 'unset' : 'full'}
              mt="2.5rem"
              onClick={() => scrollToSampleLetter()}
            >
              View sample letters
            </Button>
          )}
        </Box>
      </Flex>
      <Image src={ELettersSvg} />
    </Stack>
  )
}
