import { Box, Button, HStack, Text, Wrap } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import AngryButtonCategory from './AngryButtonCategory'
import ButtonCategory from './ButtonCategory'
import CloseButton from './CloseButton'
import { FeedbackFormData, goodOrNotGood } from './constants/feedback'
import SubmitButton from './SubmitButton'

interface OpinionBoxProps {
  goToNext: () => void
  onClose: () => void
  onSubmit: (data: FeedbackFormData) => Promise<void>
  setIsGood: (isGood: boolean) => void
  isLoading: boolean
}

const textToShow =
  'Letters and certificates from government are going digital. How was your experience using this one?'
const OpinionBox: React.FC<OpinionBoxProps> = ({
  goToNext,
  onClose,
  setIsGood,
  onSubmit,
  isLoading,
}) => {
  const [selectedOption, setSelectedOption] = useState('') // Single string to hold the selected option, should only be able to hold one
  const { handleSubmit } = useForm<FeedbackFormData>()

  const handleToggleSelection = (text: string) => {
    if (selectedOption === text) {
      setSelectedOption('')
    } else {
      setSelectedOption(text)
    }
  }

  const handleFeedbackSubmit = async (data: FeedbackFormData) => {
    data.citizenFeedbackTitle = textToShow
    data.citizenFeedbackText = selectedOption

    // Update isGood based on the selected option
    if (selectedOption === '👍 Good') {
      setIsGood(true)
    } else {
      setIsGood(false)
    }
    // Currently we do not want to show user any issues if submission happens, so we just carry on
    try {
      await onSubmit(data)
    } catch (error) {
      return
    } finally {
      goToNext()
    }
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(handleFeedbackSubmit)}>
      <HStack justify="space-between" mb="4" width="100%" align="flex-start">
        <Text textStyle={'h6'}>{textToShow}</Text>
        <CloseButton onClick={onClose} isAlignToTopRightBox={true} />
      </HStack>
      <Wrap spacing={4} w="100%">
        {goodOrNotGood.map((value) =>
          value === '👎 Not good' ? (
            <AngryButtonCategory
              key={value}
              text={value}
              onClick={() => handleToggleSelection(value)}
              isSelected={selectedOption === value}
              isFlexNeeded={true}
              height="56px"
              textStyle="h6"
            />
          ) : (
            <ButtonCategory
              key={value}
              text={value}
              onClick={() => handleToggleSelection(value)}
              isSelected={selectedOption === value}
              isFlexNeeded={true}
              height="56px"
              textStyle="h6"
            />
          ),
        )}
      </Wrap>
      <SubmitButton
        isDisabled={selectedOption === ''}
        type="submit"
        isLoading={isLoading}
      />
    </form>
  )
}

export default OpinionBox
