import { HStack, Text, VStack } from '@chakra-ui/react'

export interface IssuedBatchViewDetailsProps {
  title: string
  primaryInformation: string
  secondaryInformation?: string
}
export const IssuedBatchViewDetailsComponent = ({
  title,
  primaryInformation,
  secondaryInformation,
}: IssuedBatchViewDetailsProps): JSX.Element => {
  return (
    <VStack
      borderRadius={'4px'}
      padding="20px"
      backgroundColor={'brand.primary.100'}
      align={'left'}
      w={'100%'}
    >
      <VStack width={'100%'} align={'left'}>
        <Text textStyle={'subhead-2'} color={'grey.400'}>
          {title.toUpperCase()}
        </Text>
        <HStack align={'end'}>
          <Text textStyle={'h4'}>{primaryInformation}</Text>
          {secondaryInformation && (
            <Text textStyle={'subhead-1'} color={'grey.500'}>
              {secondaryInformation}
            </Text>
          )}
        </HStack>
      </VStack>
    </VStack>
  )
}
