import { VStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import { AdminNotFoundComponent } from '~components/AdminNotFoundComponent'
import { Loading } from '~components/Loading'
import { routes } from '~constants/routes'
import {
  useBatchId,
  useGetBatchById,
} from '~features/dashboard/hooks/dashboard.hooks'
import { isJsonString } from '~utils/stringUtils'

import { IssuedBatchViewDetails } from './components/batchViewComponents/IssuedBatchViewDetails'
import { IssuedBatchViewHeader } from './components/batchViewComponents/IssuedBatchViewHeader'
import { IssuedBatchViewLetterTable } from './components/batchViewComponents/IssuedBatchViewLetterTable'

export const IssuedBatchView = (): JSX.Element => {
  const batchId = useBatchId()
  const navigate = useNavigate()
  const { batchDetails: batch, isBatchLoading } = useGetBatchById(batchId)
  if (!batch && !isBatchLoading) {
    // This component is being returned twice, investigate if it is possible to combine into one
    return (
      <AdminNotFoundComponent
        heading="Batch could not be found."
        subtitle="Please double check the batch link or reach out to support@letters.gov.sg for help."
        buttonOnClick={() => navigate('/admin/letters')}
        buttonText="Back to dashboard"
      />
    )
  }
  if (!batch) {
    return <Loading fullscreen={true} />
  }

  const { createdAt, totalCount, readCount, notificationMethod, letters } =
    batch

  if (!letters || !isJsonString(letters[0]?.fieldValues)) {
    return (
      <AdminNotFoundComponent
        heading="Batch could not be found."
        subtitle="Please double check the batch link or reach out to support@letters.gov.sg for help."
        buttonOnClick={() => navigate('/admin/letters')}
        buttonText="Back to dashboard"
      />
    )
  }

  return (
    <VStack pt={12} spacing={'30px'} alignItems={'flex-start'} pb={14}>
      <IssuedBatchViewHeader batchDetails={batch} />
      <IssuedBatchViewDetails
        createdAt={createdAt}
        totalCount={totalCount}
        readCount={readCount}
        notificationMethod={notificationMethod}
      />
      <IssuedBatchViewLetterTable
        letters={letters}
        notificationMethod={notificationMethod}
      />
    </VStack>
  )
}
