import { Image, SimpleGrid, Text, VStack } from '@chakra-ui/react'

import DomainSvg from '~/assets/Domain.svg'
import LettersSentSvg from '~/assets/LettersSent.svg'
import MultichannelsSvg from '~/assets/Multichannels.svg'
import PermanentLetterSvg from '~/assets/PermanentLetter.svg'
import PersonalDataSvg from '~/assets/PersonalData.svg'
import TrackReadRatesSvg from '~/assets/TrackReadRates.svg'
import { useIsDesktop } from '~hooks/useIsDesktop'

import { desktopFormat, mobileFormat } from '../styles'

class LandingPageHighlightsSectionDetails {
  sectionTitle: string
  highlights: LandingPageHighlightDetails[]
}

class LandingPageHighlightDetails {
  image: string
  heading: string
  subheading: string
}

const agencyHighlights: LandingPageHighlightsSectionDetails = {
  sectionTitle: 'Send secure, official e-letters in minutes',
  highlights: [
    {
      image: LettersSentSvg,
      heading: 'Save on time and cost',
      subheading:
        'Generate and send thousands of letters in minutes - for free. Simply select a reusable template and upload a CSV to get going.',
    },
    {
      image: MultichannelsSvg,
      heading: 'Pick from multiple channels',
      subheading:
        'No more switching between Outlook and an SMS portal. Easily reach your recipients by email or SMS from a single platform.',
    },
    {
      image: TrackReadRatesSvg,
      heading: 'See analytics for your letters',
      subheading:
        'Know how many of your letters were sent successfully, and how many have been opened by recipients.',
    },
  ],
}

const citizenHighlights: LandingPageHighlightsSectionDetails = {
  sectionTitle: 'The simple, fuss-free way to receive letters from government',
  highlights: [
    {
      image: DomainSvg,
      heading: 'Know your letter is legitimate',
      subheading:
        'Letters are hosted on .gov.sg links, which are secure and can only be created by verified government officers.',
    },
    {
      image: PersonalDataSvg,
      heading: "Rest assured, it's secure",
      subheading:
        'Letters from LetterSG will never ask for your personal information like credit card details, bank account details, or OTPs.',
    },
    {
      image: PermanentLetterSvg,
      heading: 'Access your letter anytime',
      subheading:
        'Letters sent via letters.gov.sg links are accessible forever - simply click the link your personal email inbox to retrieve it.',
    },
  ],
}

export const ProductHighlightsSection = ({
  isAgencyView,
}: {
  isAgencyView: boolean
}) => {
  const isDesktop = useIsDesktop()
  const sectionDetails = isAgencyView ? agencyHighlights : citizenHighlights
  const format = isDesktop ? desktopFormat : mobileFormat
  return (
    <VStack mt={8}>
      <Text textStyle={format.heading} textAlign={'center'}>
        {sectionDetails.sectionTitle}
      </Text>
      <SimpleGrid
        columns={isDesktop ? 3 : 1}
        spacing={14}
        pt={isDesktop ? 12 : 3}
        w={'full'}
      >
        {sectionDetails.highlights.map((highlight) => (
          <VStack key={highlight.heading} spacing={3}>
            <Image src={highlight.image} h={'12rem'} mx={'auto'} />
            <Text
              textStyle={format.secondaryHeading}
              w={'full'}
              textAlign={'center'}
            >
              {highlight.heading}
            </Text>
            <Text textStyle={format.body} textAlign={'center'}>
              {highlight.subheading}
            </Text>
          </VStack>
        ))}
      </SimpleGrid>
    </VStack>
  )
}
