import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import { useEffect, useMemo } from 'react'

import { useAdminUser } from '~features/auth/hooks/auth.hooks'
import { GrowthBookFeaturesMap } from '~shared/types/feature-flag'
import { getMockGrowthbookClient } from '~shared/util/growthbook'

import { useGrowthbookApiKey } from './hooks/feature-flag.hooks'

export const GrowthBookWrapper = ({
  children,
}: React.PropsWithChildren): JSX.Element => {
  const { growthbookApiKey } = useGrowthbookApiKey()
  const { adminUser } = useAdminUser()

  const growthbook = useMemo(() => {
    if (process.env.NODE_ENV === 'development' || !growthbookApiKey)
      return getMockGrowthbookClient({
        isDevEnv: process.env.NODE_ENV === 'development',
      }) as GrowthBook
    return new GrowthBook<GrowthBookFeaturesMap>({
      apiHost: 'https://cdn.growthbook.io',
      clientKey: growthbookApiKey,
    })
  }, [growthbookApiKey])

  useEffect(() => {
    if (!growthbook) return // do nothing
    growthbook.setAttributes({
      email: adminUser?.email,
      id: adminUser?.id,
      loggedIn: !!adminUser?.id,
      agencyDomain: adminUser?.email.split('@')[1],
    })
    void growthbook.loadFeatures()
  }, [growthbook, adminUser])

  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  )
}
