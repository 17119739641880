import {
  Box,
  FormControl,
  FormHelperText,
  Hide,
  HStack,
  Show,
  Text,
  Textarea,
  Wrap,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import ButtonCategory from './ButtonCategory'
import CloseButton from './CloseButton'
import { FeedbackFormData, whyBad, whyGood } from './constants/feedback'
import SubmitButton from './SubmitButton'

interface ReasonBoxProps {
  isGood: boolean
  goToNext: () => void
  onClose: () => void
  onSubmit: (data: FeedbackFormData) => Promise<void>
  onFormDone: () => void
  isLoading: boolean
}

// React.FC seems to not be detecting that I have included the types
const ReasonBox: React.FC<ReasonBoxProps> = ({
  isGood,
  goToNext,
  onClose,
  onSubmit,
  onFormDone,
  isLoading,
}: ReasonBoxProps) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]) // Array to hold selected categories, can have multiple
  const [showTextArea, setShowTextArea] = useState(false)
  const { handleSubmit, register, watch } = useForm<FeedbackFormData>({
    defaultValues: {
      citizenFeedbackFreeText: '', // Set a default value to avoid undefined
    },
  })

  const citizenFeedbackFreeText = watch('citizenFeedbackFreeText')

  const handleToggleSelection = (text: string) => {
    if (selectedOptions.includes(text)) {
      // If already selected, remove it
      setSelectedOptions(selectedOptions.filter((option) => option !== text))
    } else {
      // If not selected, add it
      setSelectedOptions([...selectedOptions, text])
    }
    // Show text area if "Something else" is selected
    if (text === 'Something else' && !selectedOptions.includes(text)) {
      setShowTextArea(true)
    } else if (text === 'Something else' && selectedOptions.includes(text)) {
      setShowTextArea(false)
    }
  }

  const handleFeedbackSubmit = async (data: FeedbackFormData) => {
    data.citizenFeedbackTitle = textToShow
    data.citizenFeedbackText = selectedOptions
      .filter((text) => text !== 'Something else')
      .join(', ')
    data.citizenFeedbackFreeText = data.citizenFeedbackFreeText ?? 'NIL'

    // Currently we do not want to show user any issues if submission happens, so we just carry on
    try {
      await onSubmit(data)
    } catch (error) {
      return
    } finally {
      goToNext()
      onFormDone()
    }
  }

  const categoriesToShow = isGood ? whyGood : whyBad
  const textToShow = isGood
    ? '👍 What did you like about it?'
    : '👎 What did you not like about it?'

  const optionsToShow = (
    <Wrap spacing={2}>
      {categoriesToShow.map((value) => (
        <ButtonCategory
          key={value}
          text={value}
          onClick={() => handleToggleSelection(value)}
          isSelected={selectedOptions.includes(value)} // Pass the selection state
          textStyle="subhead-1"
        />
      ))}
    </Wrap>
  )
  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(handleFeedbackSubmit)}>
      <HStack justify="space-between" align="flex-start">
        <div>
          <Text textStyle={'h6'} mb="2">
            {textToShow}
          </Text>
          <Text textStyle={'caption-2'} mb="4">
            Select all options that apply
          </Text>
          <Show below="md">{optionsToShow}</Show>
        </div>
        <CloseButton onClick={onClose} isAlignToTopRightBox={true} />
      </HStack>
      <Hide below="md">{optionsToShow}</Hide>
      {showTextArea && (
        <Box mt="4">
          <FormControl>
            <Textarea
              placeholder="Tell us more"
              {...register('citizenFeedbackFreeText', {
                pattern: {
                  value: /^[a-zA-Z0-9!.*'()\n -]+$/,
                  message:
                    'Only alphanumeric characters and certain special characters are allowed.',
                },
                maxLength: {
                  value: 500,
                  message: 'Exceeded character limit.',
                },
              })}
            />
            <FormHelperText textStyle={'body-2'} mt="8px">
              {citizenFeedbackFreeText?.length ?? 0} / 500 characters left
            </FormHelperText>
          </FormControl>
        </Box>
      )}
      <SubmitButton
        isDisabled={
          selectedOptions.length === 0 ||
          (selectedOptions.includes('Something else') &&
            citizenFeedbackFreeText?.length === 0)
        }
        type="submit"
        isLoading={isLoading}
      />
    </form>
  )
}

export default ReasonBox
