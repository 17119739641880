import { HStack, Text } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { BiChevronRight } from 'react-icons/bi'

interface StepsContextProps {
  activeStep: number
}

interface StepsProps {
  children: ReactNode
  activeStep: number
}

interface StepProps {
  description: string
  index: number
}

const stepTiles = ['Template', 'Channel', 'Content']

const StepsContext = React.createContext({ activeStep: 0 })

const Steps = ({ children, activeStep }: StepsProps) => {
  return (
    <StepsContext.Provider value={{ activeStep }}>
      <HStack spacing={{ lg: 2, md: 4 }}>{children}</HStack>
    </StepsContext.Provider>
  )
}

const Step = ({ description, index }: StepProps) => {
  const { activeStep } = React.useContext<StepsContextProps>(StepsContext)
  const isActive = activeStep === index

  return (
    <Text
      maxWidth={'70%'}
      textStyle="subhead-3"
      fontSize={'14px'}
      color={isActive ? 'blue.500' : 'grey.300'}
    >
      {description}
    </Text>
  )
}

interface BulkIssueStepperProps {
  activeStepIndex: number
}

export const BulkIssueStepper = ({
  activeStepIndex,
}: BulkIssueStepperProps): JSX.Element => {
  return (
    <Steps activeStep={activeStepIndex}>
      {stepTiles.map((label, index) => {
        return (
          <>
            <Step key={label} description={label} index={index} />
            {index != stepTiles.length - 1 && (
              <BiChevronRight color="grey.200" size={16} />
            )}
          </>
        )
      })}
    </Steps>
  )
}
