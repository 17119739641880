import {
  Button,
  Circle,
  Divider,
  Flex,
  Grid,
  Hide,
  HStack,
  Image,
  Link,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import { Link as RouterLink } from 'react-router-dom'

import Arrow from '~/assets/Arrow.svg'
import NewUser from '~/assets/NewUser.svg'
import { routes } from '~constants/routes'
import { useGetTemplates } from '~features/dashboard/hooks/dashboard.hooks'
import { USER_GUIDE } from '~shared/constants/links'
import { GrowthBookFeatures } from '~shared/types/feature-flag'

interface NewUserStepProps {
  stepNumber: number
  stepHeader: string
  stepText: string
  hasNextArrow: boolean
}

export const PAGE_SIZE = 10

const NewUserStep = ({
  stepNumber,
  stepHeader,
  stepText,
  hasNextArrow,
}: NewUserStepProps): JSX.Element => (
  <VStack align="left">
    <HStack pb={2}>
      <Circle size={8} bg="brand.primary.500" color="white">
        {stepNumber}
      </Circle>
      <Hide below="md">
        {hasNextArrow && <Image width="80%" src={Arrow} />}
      </Hide>
    </HStack>
    <Text fontWeight="bold">{stepHeader}</Text>
    <Text>{stepText}</Text>
  </VStack>
)

const newUserSteps = [
  {
    stepHeader: 'Select a template for your letter',
    stepText:
      'You can see a preview of its contents, and the fields you will need to fill.',
  },
  {
    stepHeader: 'Choose how to send the letter',
    stepText:
      'Use LetterSG’s SMS or email notifications, or an external messaging tool like Postman.',
  },
  {
    stepHeader: 'Fill and upload a CSV template',
    stepText:
      'This will fill the fields in your template, and let you create links for up to 500 letters at a time.',
  },
]

export const NewUserState = (): JSX.Element => {
  const isSampleTemplateIdOn = useFeatureIsOn(
    GrowthBookFeatures.sampleTemplateId as string,
  )
  const sampleTemplateId = useFeatureValue(
    GrowthBookFeatures.sampleTemplateId,
    0,
  )
  const { templates } = useGetTemplates({
    limit: PAGE_SIZE,
    offset: 0,
  })
  const userHasTemplates =
    templates.filter((t) => t.id !== sampleTemplateId).length > 0
  const sampleTemplate = templates.find((t) => t.id === sampleTemplateId)
  const isCTAIssueSampleLetter =
    !userHasTemplates && isSampleTemplateIdOn && sampleTemplate

  return (
    <Flex
      width="100%"
      display="flex"
      justifyContent="space-between"
      py={'50px'}
    >
      <VStack width={{ lg: '70%', base: '100%' }} spacing={12} align={'left'}>
        <Flex flexDirection={{ lg: 'row', base: 'row' }}>
          <VStack align={'left'}>
            <Text textStyle={'h3'}>Welcome to LetterSG</Text>
            <Text>
              The easy way to create and send digital letters to members of the
              public.{' '}
              <Link isExternal href={USER_GUIDE}>
                Learn more
              </Link>
            </Text>
          </VStack>
          <Spacer />
          <Hide above="lg">
            <Image src={NewUser} maxW={{ md: '15%', base: '35%' }} />
          </Hide>
        </Flex>
        <Divider />
        <Text textStyle={'h3'}>How it works</Text>
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
          gap={8}
        >
          {newUserSteps.map((newUserStep, index) => (
            <NewUserStep
              key={index}
              {...newUserStep}
              stepNumber={index + 1}
              hasNextArrow={!(index + 1 === newUserSteps.length)}
            />
          ))}
        </Grid>
        <HStack spacing={4}>
          <RouterLink
            to={`/${routes.admin.index}/${routes.admin.templates.index}/issue`}
            state={
              isCTAIssueSampleLetter ? { template: sampleTemplate } : undefined
            }
          >
            <Button>
              {isCTAIssueSampleLetter
                ? 'Issue a sample letter'
                : 'Create letters'}
            </Button>
          </RouterLink>
          <Text>
            Or browse the{' '}
            <RouterLink to={routes.admin.templates.index}>
              <Link>template library</Link>
            </RouterLink>{' '}
            for existing templates
          </Text>
        </HStack>
      </VStack>
      <Hide below="lg">
        <Image
          src={NewUser}
          w="35%"
          position="absolute"
          right={20}
          top="55%"
          transform="translate(0, -65%)"
        />
      </Hide>
    </Flex>
  )
}
