import 'inter-ui/inter.css'
import '@fontsource/ibm-plex-mono'

import { Box } from '@chakra-ui/react'
import { ThemeProvider } from '@opengovsg/design-system-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useRef } from 'react'

import { theme } from '~/theme'
import { GrowthBookWrapper } from '~features/feature-flag/GrowthbookWrapper'

import { AppRouter } from './AppRouter'

export const queryClient = new QueryClient()

export const App = (): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)

  return (
    <QueryClientProvider client={queryClient}>
      <GrowthBookWrapper>
        <ThemeProvider
          theme={theme}
          resetCSS
          portalZIndex={40}
          toastOptions={{
            portalProps: {
              containerRef: ref,
            },
          }}
        >
          <AppRouter />
          {/* Allow toast to be in front of modal. Solution based on https://github.com/chakra-ui/chakra-ui/issues/6276 
            TODO: Remove this when the issue is fixed
        */}
          <Box ref={ref} />
        </ThemeProvider>
      </GrowthBookWrapper>
    </QueryClientProvider>
  )
}
