import { Box } from '@chakra-ui/react'
import { RestrictedGovtMasthead } from '@opengovsg/design-system-react'
import { PropsWithChildren, Suspense } from 'react'
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'

import { AppLayout } from '~/layouts/AppLayout'
import { routes } from '~constants/routes'

import { OfficerAnnouncementBanner } from '../components/Banner/OfficerAnnouncementBanner'
import { adminRoutes, publicRoutes } from './routes'

const router = createBrowserRouter([
  {
    path: routes.index,
    element: <AppLayout />,
    children: [
      //Public Route
      {
        children: publicRoutes,
        element: (
          <>
            <RestrictedGovtMasthead />
            <Outlet />
          </>
        ),
      },
      //Admin Route
      {
        path: routes.admin.index,
        element: (
          <>
            <OfficerAnnouncementBanner />
            <Outlet />
          </>
        ),
        children: adminRoutes,
      },
    ],
  },
])

const WithSuspense = ({ children }: PropsWithChildren) => (
  <Suspense fallback={<Box bg="neutral.100" minH="$100vh" w="100vw" />}>
    {children}
  </Suspense>
)

export const AppRouter = (): JSX.Element => {
  return (
    <WithSuspense>
      <RouterProvider router={router} />
    </WithSuspense>
  )
}
