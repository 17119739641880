import { Avatar, Box, Button, HStack, Text, VStack } from '@chakra-ui/react'
import { BiRedo, BiUndo } from 'react-icons/bi'

import { getEmailTemplateBody } from '~shared/util/message'

interface PreviewEmailNotificationProps {
  templateName: string
  userEmail: string
  shortName?: string
  logo?: string
  agencyName?: string
}

export const PreviewEmailNotification = ({
  templateName,
  shortName,
  logo,
  agencyName,
  userEmail,
}: PreviewEmailNotificationProps): JSX.Element => {
  return (
    <Box
      backgroundColor="white"
      w="full"
      borderTopRadius="2xl"
      p={6}
      mx={14}
      mt={12}
      overflow="auto"
      boxShadow="lg"
    >
      <VStack align="stretch" spacing={6} padding={4}>
        <VStack align="stretch" spacing={2}>
          <Text pl={13} fontSize="2xl" fontFamily={`'Roboto', sans-serif`}>
            Letter from {templateName}
          </Text>
          <HStack justify={'space-between'} alignItems="start">
            <HStack align="left" alignItems="start" spacing={4}>
              <Avatar bg="grey.200" />
              <VStack align="left" spacing={0}>
                <Text textStyle={'caption-1'}>
                  <b>{shortName ? `${shortName} via LetterSG` : 'LetterSG'}</b>{' '}
                  <a href="mailto:donotreply@mail.postman.gov.sg">
                    {'<'}donotreply@mail.postman.gov.sg{'>'}
                  </a>
                </Text>
                <Text textStyle={'caption-1'}>to me</Text>
              </VStack>
            </HStack>
            <Text textStyle="caption-1">Feb 22, 2024, 2:37 PM</Text>
          </HStack>
        </VStack>
        {/* Override the Chakra display styles of the email html*/}
        <style>
          {`
            .email-content .email-table p {
            margin-bottom: 0.875rem;
            margin-top: 0.875rem;
          }
        `}
        </style>
        <div
          dangerouslySetInnerHTML={{
            __html: getEmailTemplateBody({
              agencyName,
              logo,
              userEmail,
              letterPublicId: '{{letter_link}}',
              domainName: window.location.origin,
            }),
          }}
        />
        <HStack justify="left" mt={6}>
          <Button
            leftIcon={<BiUndo />}
            size="xs"
            variant="ghost"
            color="grey.300"
            borderWidth={0.5}
            borderRadius={20}
          >
            Reply
          </Button>
          <Button
            leftIcon={<BiRedo />}
            size="xs"
            variant="ghost"
            color="grey.300"
            borderWidth={0.5}
            borderRadius={20}
          >
            Forward
          </Button>
        </HStack>
      </VStack>
    </Box>
  )
}
