import { HStack, Text } from '@chakra-ui/react'
import { Badge, Menu } from '@opengovsg/design-system-react'

export interface DownloadMenuProps {
  onDownloadCsv: () => void
  onDownloadPdf: () => void
}

export const DownloadMenu = ({
  onDownloadCsv,
  onDownloadPdf,
}: DownloadMenuProps): JSX.Element => {
  return (
    <Menu isStretch size={'md'}>
      {({ isOpen }) => (
        <>
          <Menu.Button isOpen={isOpen}>Download</Menu.Button>
          <Menu.List mt={0}>
            <Menu.Item onClick={onDownloadCsv}>CSV report</Menu.Item>
            <Menu.Item onClick={onDownloadPdf}>
              <HStack>
                <Text>PDFs</Text>
                <Badge variant="subtle" borderRadius={'xl'}>
                  New
                </Badge>
              </HStack>
            </Menu.Item>
          </Menu.List>
        </>
      )}
    </Menu>
  )
}
