import {
  Button,
  HStack,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { useEffect } from 'react'

import { GrowthBookFeatures } from '~shared/types/feature-flag'

interface AnnouncementModalFeatureProps {
  title: string
  description: string
  icon: string
  image: string
}

export const AnnnouncementModal = (): JSX.Element | null => {
  const { onClose, onOpen, isOpen } = useDisclosure()

  const announcementModalFeatures = useFeatureValue(
    GrowthBookFeatures.announcementModal,
    [],
  ) as AnnouncementModalFeatureProps[]

  useEffect(() => {
    if (!announcementModalFeatures.length) {
      return
    }

    const announcementString = JSON.stringify(announcementModalFeatures)
    const previousAnnouncement = localStorage.getItem('announcement')
    if (previousAnnouncement !== announcementString) {
      localStorage.setItem('announcement', announcementString)
      onOpen()
    }
  }, [announcementModalFeatures])

  if (!announcementModalFeatures.length) {
    return null
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent padding="5px">
        <ModalHeader>
          <Text>What&apos;s new?</Text>
          <ModalCloseButton />
        </ModalHeader>
        <VStack
          px="32px"
          pt="20px"
          spacing="20px"
          textColor={'grey.500'}
          fontSize={'18px'}
          align={'left'}
        >
          {announcementModalFeatures.map((feature, index) => (
            <HStack spacing="10px" display={'flex'} align="top" key={index}>
              <Text>{feature.icon}</Text>
              <VStack
                align={'left'}
                spacing="0"
                fontWeight={'500'}
                lineHeight={'24px'}
              >
                <Text textColor={'grey.700'}>{feature.title}</Text>
                <Text
                  fontSize={'16px'}
                  textColor={'grey.500'}
                  fontWeight={'400'}
                  lineHeight={'24px'}
                >
                  {feature.description}
                </Text>
              </VStack>
            </HStack>
          ))}
          {announcementModalFeatures[0].image && (
            <Image src={announcementModalFeatures[0].image} />
          )}
        </VStack>

        <ModalFooter>
          <Button type="submit" onClick={onClose}>
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
