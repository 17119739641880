import { LandingPageSampleUseCaseProps } from '../sections/SampleUseCaseSection'

// These sample letters with these public IDs are created on prod only.
// They are not guaranteed to work on staging or dev.
export const SAMPLE_LETTERS: LandingPageSampleUseCaseProps[] = [
  {
    name: "Advisory to seek HDB's prior approval for renting out",
    agency: 'Housing & Development Board',
    publicId: 'y0c83-6vuza-fiaeu-8eqb9',
    thumbnailUrl: 'https://file.go.gov.sg/3a08sa.png',
  },
  {
    name: 'Unsuccessful PV Letter',
    agency: 'Pei Hwa Presbyterian Primary School',
    publicId: 't7uvz-g2d3k-s6cgo-43heg',
    thumbnailUrl: 'https://file.go.gov.sg/p8p6ns.png',
  },
  {
    name: 'Overtime Letter',
    agency: 'Immigration & Checkpoints Authority',
    publicId: '2mvwt-nm8kd-soujf-nre5y',
    thumbnailUrl: 'https://file.go.gov.sg/gppgm6.png',
  },
  {
    name: 'Healthcare Financing Certificate',
    agency: 'Sengkang General Hospital',
    publicId: 'cyp8f-nzd5d-9z7tf-3tpwg',
    thumbnailUrl: 'https://file.go.gov.sg/1gg365.png',
  },
]
