import { useToast } from '@opengovsg/design-system-react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { WretchError } from 'wretch/types'

import { adminQueryKeys } from '~constants/query-keys'
import { toastIds } from '~constants/toast-id'
import { api } from '~lib/api'
import { GetBatchDto } from '~shared/dtos/batches.dto'
import {
  BulkLetterValidationResultDto,
  BulkLetterValidationResultError,
  CitizenNotificationMethod,
  CreateBulkLetterDto,
  IssueSampleLetterDto,
} from '~shared/dtos/letters.dto'
import { GetTemplateDto } from '~shared/dtos/templates.dto'
import { citizenNotificationMethodToString } from '~utils/stringUtils'

export const useTemplateId = (): { templateId: number } => {
  const { templateId } = useParams()
  if (!templateId) {
    if (!templateId) throw new Error('No templateId provided')
  }
  return { templateId: Number(templateId) }
}

export const useGetTemplateById = (
  templateId: number,
  onSuccess?: () => void,
) => {
  const { data, isLoading, error } = useQuery(
    adminQueryKeys.templates({ templateId }),
    () => {
      return api.url(`/templates/${templateId}`).get().json<GetTemplateDto>()
    },
    {
      onSuccess: () => {
        onSuccess?.()
      },
      retry: false,
    },
  )

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return { template: data!, isTemplatesLoading: isLoading, error }
}

export const useValidateBulkLetterMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void
  onError?: (res: BulkLetterValidationResultError[]) => void
} = {}) => {
  const toast = useToast({ position: 'top' })

  return useMutation(
    async (
      body: CreateBulkLetterDto,
    ): Promise<BulkLetterValidationResultDto> => {
      return await api
        .url(`/letters/validate`)
        .post(body)
        .json<BulkLetterValidationResultDto>()
    },
    {
      onSuccess: () => {
        onSuccess?.()
      },
      onError: (e: WretchError) => {
        const err = e.json as BulkLetterValidationResultDto
        if (!err.errors) return
        onError?.(err.errors)
      },
      retry: false,
    },
  )
}

export const useCreateBulkLetterMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: GetBatchDto) => void
  onError?: (errors?: BulkLetterValidationResultError[]) => void
} = {}) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  return useMutation(
    async (body: CreateBulkLetterDto): Promise<GetBatchDto> => {
      return await api.url(`/letters/bulks`).post(body).json<GetBatchDto>()
    },
    {
      onSuccess: async (res: GetBatchDto) => {
        // invalidate letters dashboard queries
        await queryClient.invalidateQueries(adminQueryKeys.batches())
        onSuccess?.(res)
      },
      onError: (e: WretchError) => {
        const err = e.json as BulkLetterValidationResultDto
        onError?.(err?.errors)
        if (!toast.isActive(toastIds.createBulk)) {
          toast({
            id: toastIds.createBulk,
            title: `Failed to create letters. Please try again.`,
            status: 'error',
          })
        }
      },
    },
  )
}

export const useSendSampleSmsMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void
  onError?: () => void
} = {}) => {
  const toast = useToast({ isClosable: true })
  const { getValues } = useFormContext()
  const citizenNotificationMethod = getValues(
    'citizenNotificationMethod',
  ) as CitizenNotificationMethod
  return useMutation(
    async (body: IssueSampleLetterDto): Promise<void> => {
      return await api.url(`/notifications/sample`).post(body).json<void>()
    },
    {
      onSuccess: () => {
        toast({
          title: `Test ${citizenNotificationMethodToString(
            citizenNotificationMethod,
          )} sent successfully`,
          status: 'success',
        })
        onSuccess?.()
      },
      onError: () => {
        toast({
          title: `Failed to send test ${citizenNotificationMethodToString(
            citizenNotificationMethod,
          )}`,
          status: 'error',
        })
        onError?.()
      },
    },
  )
}
