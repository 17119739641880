import {
  Flex,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

import ExitTemplateEditor from '~/assets/ExitTemplateEditor.svg'

interface ExitConfirmationModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}
export const ExitConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
}: ExitConfirmationModalProps): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <Image
          alignSelf="center"
          src={ExitTemplateEditor}
          borderTopRadius={'8px'}
        />
        <VStack p="32px" alignItems={'left'}>
          <Text textStyle={'h4'} fontSize={'24px'}>
            Your information will not be saved.
          </Text>
          <Text mb="1rem" textStyle={'subhead-1'}>
            Are you sure you want to leave this page?
          </Text>
        </VStack>
        <Flex marginLeft={'auto'} pb="32px" px="32px">
          <Button variant="outline" mr={3} onClick={onClose} border="0">
            Cancel
          </Button>
          <Button onClick={onConfirm}>Confirm</Button>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
