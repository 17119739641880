import { useToast } from '@opengovsg/design-system-react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { publicQueryKeys } from '~constants/query-keys'
import { api } from '~lib/api'
import { GetLetterPublicDto } from '~shared/dtos/letters.dto'

export const useLetterPublicId = (): { letterPublicId: string } => {
  const { letterPublicId } = useParams<{ letterPublicId: string }>()
  if (!letterPublicId) throw new Error('No letterPublicId provided')
  return { letterPublicId }
}

export const useGetLetterByPublicId = ({
  letterPublicId,
}: {
  letterPublicId: string
}) => {
  const {
    data,
    isLoading,
    error,
    refetch: refetchLetter,
  } = useQuery<GetLetterPublicDto>(
    publicQueryKeys.letters(letterPublicId),
    () => {
      return api
        .url(`/public/letters/${letterPublicId}`)
        .get()
        .json<GetLetterPublicDto>()
    },
    { enabled: !!letterPublicId, retry: false, refetchOnWindowFocus: false },
  )
  return { letter: data, isLetterLoading: isLoading, error, refetchLetter }
}

export const useGetPdfByPublicId = () => {
  const toast = useToast()
  const { isLoading, mutate } = useMutation(
    (letterPublicId: string) =>
      api
        .url(`/public/letters/${letterPublicId}/pdfs`)
        .get()
        .json<{ url: string }>(),
    {
      onSuccess: (res: { url: string }) => {
        window.open(res.url)
      },
      onError: () => {
        toast({
          status: 'error',
          description: 'Your PDF failed to download. Please try again.',
        })
      },
    },
  )
  return { isPdfLoading: isLoading, getPdf: mutate }
}
