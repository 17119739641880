import {
  Box,
  Button,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Progress,
  Text,
  Tooltip,
  useClipboard,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useState } from 'react'
import { BiCheckCircle, BiCopy, BiHide, BiShow } from 'react-icons/bi'
import { BsFillQuestionCircleFill } from 'react-icons/bs'

import { AppFooter } from '~/app/AppFooter'
import APIIntegration from '~/assets/APIIntegration.svg'
import { Navbar } from '~components/Navbar'

import { RegenerateApiKeyConfirmationModal } from './components/RegenerateApiKeyConfirmationModal'
import {
  useCreateApiKeyForUser,
  useGetApiKeySuffixForUser,
} from './hooks/api.hooks'
export const ApiIntegrationPage = (): JSX.Element => {
  const {
    onCopy,
    setValue: setCurrApiKey,
    hasCopied,
    value: currApiKey,
  } = useClipboard('')

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { createApiKeyMutation, apiKeyLoading } = useCreateApiKeyForUser()
  const { apiKeySuffix } = useGetApiKeySuffixForUser()

  const handleRegenerateApiKey = async () => {
    const apiKeyData = await createApiKeyMutation()
    setCurrApiKey(apiKeyData.apiKey)
  }
  const [show, setShow] = useState(true)

  return (
    <VStack minWidth="100%" align="stretch" spacing={0}>
      <Navbar />
      <Box px={{ lg: '10%', base: '5%' }} minH="40vh" maxH="40vh">
        <VStack
          align={'left'}
          paddingTop={'50px'}
          paddingBottom={'20px'}
          spacing="30px"
        >
          <VStack align="left">
            <Text textStyle={'h3'}>API Key</Text>
            <Text>
              Get your API key and connect it to your product to enjoy
              LetterSG’s features. View{' '}
              <a
                href="https://guide.letters.gov.sg/developer-guide/upcoming-api-documentation"
                style={{ color: '#2D4193', textDecoration: 'underline' }}
                target="_blank"
                rel="noreferrer"
              >
                our documentation
              </a>{' '}
              to see how to integrate APIs for LetterSG.
            </Text>
          </VStack>
          <VStack align="left" spacing="12px">
            <VStack align="left">
              <HStack>
                <Text fontSize={'16px'} fontWeight={'600'}>
                  Your API Key
                </Text>
                <Box position="relative" float="left" display="flex">
                  <Tooltip
                    label="All the letters you create using this API key will be linked to this account and can be found on the dashboard."
                    placement="right"
                    fontSize={'sm'}
                    size="xs"
                    hasArrow
                    backgroundColor={'grey.900'}
                  >
                    <div style={{ display: 'block', width: '100%' }}>
                      <BsFillQuestionCircleFill />
                    </div>
                  </Tooltip>
                </Box>
              </HStack>
              <Text fontSize={'14px'}>
                Please save a copy of your API key immediately as this will only
                be shown once. If you leave or refresh this page, the API key
                will no longer be accessible.
              </Text>
            </VStack>
            <HStack align="left" spacing="12px">
              <VStack
                align="left"
                spacing="0px"
                maxH="48px"
                minH="48px"
                minWidth={'600px'}
              >
                <InputGroup>
                  <Input
                    textColor={'grey.500'}
                    disabled={true}
                    _disabled={{ backgroundColor: 'white' }}
                    placeholder={'Your key is shown here'}
                    value={
                      show && currApiKey != ''
                        ? currApiKey
                        : apiKeySuffix
                        ? `*********************${apiKeySuffix}`
                        : undefined
                    }
                    id={'api-key'}
                  />
                  <InputRightElement width="90px">
                    <HStack spacing="0">
                      {show && currApiKey != '' ? (
                        <Tooltip
                          label={'Hide API Key'}
                          fontSize={'xs'}
                          size="xs"
                          hasArrow
                          placement="top"
                        >
                          <IconButton
                            onClick={() => setShow(!show)}
                            isDisabled={currApiKey === ''}
                            aria-label="hide"
                            variant="clear"
                            size={'sm'}
                            icon={<BiHide size={20} />}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          label={'Show API Key'}
                          fontSize={'xs'}
                          size="xs"
                          hasArrow
                          placement="top"
                        >
                          <IconButton
                            onClick={() => setShow(!show)}
                            isDisabled={currApiKey === ''}
                            aria-label="show"
                            variant="clear"
                            size={'sm'}
                            icon={<BiShow size={20} />}
                          />
                        </Tooltip>
                      )}

                      {hasCopied ? (
                        <Tooltip
                          label="Copied to clipboard!"
                          fontSize={'xs'}
                          size="xs"
                          hasArrow
                          placement="top"
                        >
                          <IconButton
                            onClick={onCopy}
                            aria-label="copied"
                            variant="clear"
                            size={'sm'}
                            color="green.700"
                            icon={<BiCheckCircle size={20} />}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          label="Copy API Key"
                          fontSize={'xs'}
                          size="xs"
                          hasArrow
                          placement="top"
                        >
                          <IconButton
                            onClick={onCopy}
                            aria-label="copy"
                            variant="clear"
                            padding={0}
                            size={'sm'}
                            isDisabled={currApiKey === ''}
                            icon={<BiCopy size={20} />}
                          />
                        </Tooltip>
                      )}
                    </HStack>
                  </InputRightElement>
                </InputGroup>
                {apiKeyLoading && <Progress size="xs" isIndeterminate />}
              </VStack>
              <Button
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={apiKeySuffix ? onOpen : handleRegenerateApiKey}
              >
                {apiKeySuffix ? 'Regenerate API key' : 'Generate new API key'}
              </Button>
            </HStack>
          </VStack>
        </VStack>
      </Box>
      <RegenerateApiKeyConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onConfirm={handleRegenerateApiKey}
      />
      <Box display="flex" justifyContent={'flex-end'} padding={'0px'}>
        <Image src={APIIntegration} boxSize={'500px'} />
      </Box>
      <AppFooter containerProps={{ px: '3rem' }} />
    </VStack>
  )
}
