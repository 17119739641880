import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  HStack,
  Link,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'

import { USER_GUIDE_EDITOR } from '~shared/constants/links'

type EditorFeature = {
  icon: string
  title: string
  description: string
}

interface EditorIntroModalProps {
  isOpen: boolean
  onClose: () => void
  introHeader?: string
  showWarning?: boolean
  editorFeatures?: EditorFeature[]
}

const EDITOR_FEATURES: EditorFeature[] = [
  {
    icon: '🖼️',
    title: 'Replace images',
    description:
      "Double-click on the image you want to replace, choose the new image that you want to upload, and you're all set!",
  },
  {
    icon: '✏️',
    title: 'Format personalised fields',
    description:
      'Enclose the personalised fields in curly brackets, eg. {{name}}. Fields should only contain letters, numbers and/ or underscore. (A-Z,a-z,0-9,_).',
  },
]

export const EditorIntroModal = ({
  isOpen,
  onClose,
  introHeader = 'How to use this editor',
  showWarning = false,
  editorFeatures = EDITOR_FEATURES,
}: EditorIntroModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent padding="5px">
        <ModalHeader>
          <Text>{introHeader}</Text>
          <ModalCloseButton />
        </ModalHeader>
        <VStack
          px="32px"
          pt="20px"
          spacing={8}
          textColor={'grey.500'}
          fontSize={'18px'}
          align="left"
        >
          {showWarning && (
            <Alert status="info" variant="subtle" bg={'blue.50'} pt="10px">
              <HStack align={'top'} spacing="0">
                <AlertIcon />
                <AlertDescription fontSize={'md'}>
                  Please be aware that any changes to this template are
                  permanent and will apply to all users once saved.
                </AlertDescription>
              </HStack>
            </Alert>
          )}
          <VStack spacing="20px" align="left">
            {editorFeatures.map((feature: EditorFeature, index: number) => (
              <HStack spacing="10px" display={'flex'} align="top" key={index}>
                <Text>{feature.icon}</Text>
                <VStack
                  align={'left'}
                  spacing="0"
                  fontWeight={'500'}
                  lineHeight={'24px'}
                >
                  <Text textColor={'grey.700'}>{feature.title}</Text>
                  <Text
                    fontSize={'16px'}
                    textColor={'grey.500'}
                    fontWeight={'400'}
                    lineHeight={'24px'}
                  >
                    {feature.description}
                  </Text>
                </VStack>
              </HStack>
            ))}
          </VStack>
          <Text
            fontSize={'16px'}
            textColor={'grey.500'}
            fontWeight={'400'}
            lineHeight={'24px'}
          >
            For a more complete guide, refer to our template editing guide{' '}
            <Link href={USER_GUIDE_EDITOR} isExternal>
              here
            </Link>
            .
          </Text>
        </VStack>
        <ModalFooter>
          <Button type="submit" onClick={onClose}>
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
