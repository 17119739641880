import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useMatch } from 'react-router-dom'

import { GetTemplateDto } from '~shared/dtos/templates.dto'

import { PreviewTemplateInOverlay } from './components/PreviewTemplateInOverlay'
import {
  ToggleButtonDirection,
  ToggleTemplateButton,
} from './components/ToggleTemplateButton'

interface TemplatePreviewModalProps {
  isOpen: boolean
  onClose: () => void
  templates: GetTemplateDto[]
  initialIndex: number
  isArchived: boolean
}

export const TemplatePreviewModal = ({
  isOpen,
  onClose,
  templates,
  initialIndex,
  isArchived,
}: TemplatePreviewModalProps) => {
  const [currentIndex, setCurrentIndex] = useState<number>(initialIndex)

  useEffect(() => {
    // set current index
    setCurrentIndex(initialIndex)
  }, [initialIndex])

  return (
    <Modal
      size={'full'}
      isOpen={isOpen}
      onClose={onClose}
      colorScheme="none"
      onOverlayClick={onClose}
    >
      <ModalOverlay h="100%" bg={'base.canvas.overlay'} />
      <ModalContent bg={'none'} pointerEvents="none">
        <PreviewTemplateInOverlay
          template={templates[currentIndex]}
          onClose={onClose}
          isArchived={Boolean(isArchived)}
        />
        <ToggleTemplateButton
          direction={ToggleButtonDirection.Left}
          isDisabled={currentIndex === 0}
          onButtonClick={() => setCurrentIndex(currentIndex - 1)}
        />
        <ToggleTemplateButton
          direction={ToggleButtonDirection.Right}
          isDisabled={currentIndex === templates.length - 1}
          onButtonClick={() => setCurrentIndex(currentIndex + 1)}
        />
      </ModalContent>
    </Modal>
  )
}
