import { IsDefined, IsNumber, IsOptional } from 'class-validator'

export enum NotificationStatus {
  SENT = 'SENT',
  FAIL = 'FAIL',
  PENDING = 'PENDING',
  INVALID_RECIPIENT = 'INVALID_RECIPIENT',
}

export class ResentNotificationsDto {
  @IsOptional()
  letterIds?: number[]
  @IsNumber()
  @IsOptional()
  lastTimestamp?: number
}
