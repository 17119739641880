export interface FeedbackFormData {
  citizenFeedbackTitle: string
  citizenFeedbackText: string
  citizenFeedbackFreeText?: string
}

export interface LocalStorageCitizenFeedbackData {
  timesVisited: number
  isFormDone: boolean
  lastShownTime: string
}

export interface ButtonCategoryProps {
  text: string
  onClick: () => void
  selectedColor?: string
  selectedBackground?: string
  selectedHover?: string
  isSelected?: boolean
  isFlexNeeded?: boolean
  height?: string
  textStyle: string
}

export const goodOrNotGood = ['👎 Not good', '👍 Good']

export const whyGood = [
  'Environmentally friendly',
  'Can download and store',
  'Easy to use',
  'Trustworthy',
  'Something else',
]

export const whyBad = [
  'I prefer physical copies',
  'Worried it may be a scam',
  'Difficult to use',
  'Something else',
]
