import { SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { useFeatureValue } from '@growthbook/growthbook-react'

import { useIsDesktop } from '~hooks/useIsDesktop'
import { GrowthBookFeatures } from '~shared/types/feature-flag'

import { LetterCard } from '../components/LetterCard'
import { SAMPLE_LETTERS } from '../data/sampleUseCases'
import { desktopFormat, mobileFormat } from '../styles'

export interface LandingPageSampleUseCaseProps {
  name: string
  agency: string
  publicId: string
  thumbnailUrl: string
}

export const SampleUseCaseSection = () => {
  const isDesktop = useIsDesktop()
  const format = isDesktop ? desktopFormat : mobileFormat
  const landingPageSampleUseCases = useFeatureValue(
    GrowthBookFeatures.landingPageSampleUseCases,
    [],
  ) as LandingPageSampleUseCaseProps[]

  return (
    <VStack color={'standard.white'}>
      <Text textStyle={format.heading} textAlign={'center'}>
        Supporting a range of use cases for agencies
      </Text>
      <Text
        textStyle={format.subHeading}
        textAlign={'center'}
        py={5}
        w={isDesktop ? '80%' : '100%'}
      >
        You can click the letters below to preview them
      </Text>
      <SimpleGrid
        columns={isDesktop ? 4 : 2}
        spacingX={isDesktop ? 8 : 3}
        spacingY="4rem"
        w="100%"
        pt={5}
      >
        {(landingPageSampleUseCases.length == 0
          ? SAMPLE_LETTERS
          : landingPageSampleUseCases
        ).map((letter) => (
          <LetterCard
            key={letter.publicId}
            name={letter.name}
            agency={letter.agency}
            publicId={letter.publicId}
            thumbnailUrl={letter.thumbnailUrl}
          />
        ))}
      </SimpleGrid>
    </VStack>
  )
}
