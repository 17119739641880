import { ToastProps } from '@opengovsg/design-system-react'

export const components = {
  TableContainer: {
    baseStyle: {
      borderRadius: '4px',
    },
  },
  Button: {
    baseStyle: {
      borderRadius: '4px',
    },
  },
  Toast: {
    variants: {
      subtle: (props: ToastProps) => ({
        wrapper: {
          borderColor:
            props.colorScheme === 'warning' ? 'yellow.300' : undefined,
        },
        icon: {
          fill: props.colorScheme === 'warning' ? 'yellow.300' : undefined,
        },
      }),
    },
  },
  Infobox: {
    variants: {
      warning: {
        icon: {
          color: 'yellow.300',
        },
      },
    },
  },
}
