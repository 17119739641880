import { Box, Text, VStack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

const WATERMARK_SPACING = 480

interface WatermarkProps {
  label: string
}

const Watermark = ({ label }: WatermarkProps) => {
  return (
    <Text
      textStyle={'h0'}
      color={'grey.200'}
      transform={'rotate(-30deg)'}
      opacity={0.5}
    >
      {label}
    </Text>
  )
}

interface WatermarkOverlayProps {
  boxHeight: number | null
}

export const WatermarkOverlay = ({ boxHeight }: WatermarkOverlayProps) => {
  const [numberWatermarks, setNumberWatermarks] = useState<number>(1)
  useEffect(() => {
    boxHeight
      ? setNumberWatermarks(Math.floor(boxHeight / WATERMARK_SPACING))
      : setNumberWatermarks(0)
  }, [boxHeight])

  return (
    <Box
      borderRadius="10px"
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      zIndex="1"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <VStack spacing={WATERMARK_SPACING}>
        {Array(numberWatermarks).fill(<Watermark label={'Preview'} />)}
      </VStack>
    </Box>
  )
}
