import {
  Box,
  Button,
  HStack,
  Link,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import { useState } from 'react'
import { BiX } from 'react-icons/bi'
import { Navigate, useNavigate } from 'react-router-dom'

import { Loading } from '~components/Loading'
import { routes } from '~constants/routes'
import { PreviewTemplate } from '~features/issue/components/previews/PreviewTemplate'
import { TemplatePreviewThumbnail } from '~features/template-library/components/thumbnail/TemplatePreviewThumbnail'
import { REQUEST_NEW_TEMPLATE } from '~shared/constants/links'
import { DEFAULT_TEMPLATES } from '~shared/constants/templates'
import {
  GrowthBookFeatures,
  GrowthBookFeaturesMap,
} from '~shared/types/feature-flag'
import { replaceWithParams } from '~shared/util/letters'

export const SelectTemplateLayoutPage = (): JSX.Element => {
  const navigate = useNavigate()
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)

  const agencyMapping = useFeatureValue(
    GrowthBookFeatures.agencyMapping as string,
    {},
  ) as GrowthBookFeaturesMap[GrowthBookFeatures.agencyMapping]

  const defaultTemplates = useFeatureValue(
    GrowthBookFeatures.defaultTemplates as string,
    DEFAULT_TEMPLATES,
  ) as GrowthBookFeaturesMap[GrowthBookFeatures.defaultTemplates]

  const defaultTemplatesWithParams = defaultTemplates.map((template) => ({
    ...template,
    html: agencyMapping
      ? replaceWithParams(template.html, agencyMapping, '__', '__')
      : template.html,
  })) as { name: string; html: string; description: string }[]

  const isTemplateCreateOn = useFeatureIsOn(
    GrowthBookFeatures.templateCreate as string,
  )

  if (!isTemplateCreateOn)
    return <Navigate to={`${routes.admin.index}/${routes.admin.letters}`} />

  return (
    <VStack h="100%" display="flex" flexDirection="column">
      <HStack
        w={'100%'}
        py={4}
        px={10}
        display={'flex'}
        justify={'space-between'}
        position={'fixed'}
        zIndex={10}
        backgroundColor={'grey.50'}
        pointerEvents="auto"
      >
        <HStack spacing={0}>
          <Button
            onClick={() =>
              navigate(`/${routes.admin.index}/${routes.admin.templates.index}`)
            }
            variant={'unstyled'}
            border={'none'}
            id="exit-select-template"
          >
            <BiX size={'1.7rem'} />
          </Button>
          <Text
            textStyle={'h6'}
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            maxWidth="60vw"
          >
            Choose a template layout
          </Text>
        </HStack>
        <HStack spacing={4}>
          <Button
            alignSelf="start"
            isDisabled={selectedIndex === null}
            onClick={() =>
              navigate(
                `/${routes.admin.index}/${routes.admin.templates.index}/edit`,
                {
                  state: selectedIndex !== null && {
                    template: {
                      html: defaultTemplatesWithParams[selectedIndex].html,
                      name: agencyMapping.shortName
                        ? `${
                            agencyMapping.shortName
                          } - ${defaultTemplatesWithParams[
                            selectedIndex
                          ].name.replace('- ', '')}` // replace - with empty string
                        : defaultTemplatesWithParams[selectedIndex].name,
                    },
                  },
                },
              )
            }
          >
            Start with this
          </Button>
        </HStack>
      </HStack>
      <HStack
        w="full"
        justify="space-between"
        pt={13}
        display="flex"
        overflow="auto"
        spacing="0"
        h="full"
      >
        <VStack
          p={10}
          align="start"
          w={'40%'}
          height="100%"
          bg={'white'}
          overflow="auto"
        >
          {defaultTemplatesWithParams.length > 0 ? (
            <SimpleGrid
              minChildWidth={'180px'}
              columns={[1, 2]}
              gap={10}
              w={'100%'}
            >
              {defaultTemplatesWithParams.map(
                ({ name, html, description }, index: number) => (
                  // styling below is adapted from TemplateCard
                  // TODO: check if we should make TemplateCard styling
                  // reusable for both components, currently not done since
                  // the styling for the box outline is slightly different
                  <VStack spacing={5} align={'left'} key={index}>
                    <Box
                      borderRadius="lg"
                      id="template-layout-card"
                      p={6}
                      border={index === selectedIndex ? '2px solid' : 'none'}
                      borderColor={
                        index === selectedIndex
                          ? 'interaction.main.default'
                          : 'none'
                      }
                      backgroundColor={
                        index === selectedIndex ? 'blue.100' : 'grey.50'
                      }
                      _hover={{
                        cursor: 'pointer',
                        backgroundColor: 'blue.100',
                      }}
                      onClick={() => setSelectedIndex(index)}
                    >
                      <TemplatePreviewThumbnail
                        html={html}
                        align={'center'}
                        aspectRatio={'0.71'}
                        borderRadius={'4px'}
                        bg="white"
                        shadow={'sm'}
                      />
                    </Box>
                    <VStack spacing={1} align={'left'}>
                      <Text noOfLines={2} textStyle={'subhead-1'}>
                        {name}
                      </Text>
                      <Text textStyle={'body-1'}>{description}</Text>
                    </VStack>
                  </VStack>
                ),
              )}
            </SimpleGrid>
          ) : (
            <Loading />
          )}
          <Text textStyle="subhead-2" pt={12}>
            Template layout not supported yet?{' '}
            <Link href={REQUEST_NEW_TEMPLATE} isExternal>
              Get help to onboard your template here
            </Link>
            .
          </Text>
        </VStack>
        <VStack width="60%" height={'100%'} bg={'slate.800'} overflow={'auto'}>
          <PreviewTemplate
            templateHtml={
              selectedIndex !== null && defaultTemplatesWithParams
                ? defaultTemplatesWithParams[selectedIndex].html
                : ''
            }
            isPlaceholdersHighlighted={true}
          />
        </VStack>
      </HStack>
    </VStack>
  )
}
