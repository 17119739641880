import { Th } from '@chakra-ui/react'

export const HeaderCell = ({ children }: React.PropsWithChildren) => {
  return (
    <Th
      textColor="base.content.default"
      textStyle="subhead-2"
      textTransform="none"
      fontWeight="500"
      fontSize="0.875rem"
      letterSpacing="none"
      h="4rem"
    >
      {children}
    </Th>
  )
}
