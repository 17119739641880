import { Box, BoxProps, VStack } from '@chakra-ui/react'
import { forwardRef, LegacyRef } from 'react'

import { Loading } from '~components/Loading'
import { sanitizeHtml } from '~shared/util/html-sanitizer'
import { HEIGHT_A4, WIDTH_A4 } from '~utils/htmlUtils'

interface LetterViewerProps extends BoxProps {
  html: string | undefined
  isLoading: boolean
  transformScale: number
  displayResponsive?: boolean | undefined
}

export const LetterViewerInner = (
  {
    html,
    isLoading,
    transformScale,
    displayResponsive = false,
  }: LetterViewerProps,
  ref: LegacyRef<HTMLDivElement> | undefined,
): JSX.Element => {
  if (isLoading || !html) {
    return <Loading />
  }
  const cleanHtml = sanitizeHtml(html)

  return (
    <VStack
      transform={displayResponsive ? undefined : `scale(${transformScale})`}
      transformOrigin={displayResponsive ? undefined : 'top'}
      spacing={0}
      ref={ref}
    >
      <Box
        id="letter-viewer"
        minHeight={HEIGHT_A4}
        width={displayResponsive ? '90vw' : WIDTH_A4}
        maxW={WIDTH_A4}
        bg="white"
      >
        <div dangerouslySetInnerHTML={{ __html: cleanHtml }}></div>
      </Box>
    </VStack>
  )
}
export const LetterViewer = forwardRef(LetterViewerInner)
