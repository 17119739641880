import { UseToastOptions } from '@chakra-ui/react'
import {
  // eslint-disable-next-line no-restricted-imports
  useToast as useOgpToast,
} from '@opengovsg/design-system-react'
import { useRef } from 'react'

const DEFAULT_TOAST_OPTIONS: UseToastOptions = {
  duration: 3000,
  isClosable: true,
  position: 'top',
}

export const useToast = (props: UseToastOptions = {}) => {
  return useOgpToast({ ...DEFAULT_TOAST_OPTIONS, ...props })
}

export const useReplaceableToast = (props: UseToastOptions = {}) => {
  const toast = useOgpToast({ ...DEFAULT_TOAST_OPTIONS, ...props })
  const toastIdsRef = useRef<Record<string, string>>({})

  return {
    replaceableToast: ({ id, ...rest }: UseToastOptions): string => {
      // If a toast with the provided ID is already active, close it
      if (id && toastIdsRef.current[id]) {
        toast.close(toastIdsRef.current[id])
      }

      // Show the new toast
      const toastId = toast({
        id,
        ...rest,
        onCloseComplete: () => {
          // Remove ID from the toast IDs mapping when toast is closed
          if (id) {
            delete toastIdsRef.current[id]
          }

          if (rest.onCloseComplete) {
            rest.onCloseComplete()
          }
        },
      }) as string

      if (id) {
        toastIdsRef.current[id] = toastId
      }

      return toastId
    },
    closeToast: (id: string) => {
      if (id && toastIdsRef.current[id]) {
        toast.close(toastIdsRef.current[id])
      }
    },
  }
}
