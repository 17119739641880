import { useMutation } from '@tanstack/react-query'

import { useToast } from '~hooks/useToast'
import { api } from '~lib/api'
import { CitizenFeedbackDto, FeedbackDto } from '~shared/dtos/feedback.dto'

export const useSendFeedbackMutation = ({
  onSuccess,
}: {
  onSuccess?: () => void
} = {}) => {
  const toast = useToast()

  return useMutation(
    async (feedbackData: FeedbackDto) => {
      return api.url('/feedback').post(feedbackData).res()
    },
    {
      onSuccess: () => {
        onSuccess?.()
        toast({
          title: 'Thanks for your feedback!',
          status: 'success',
        })
      },
      onError: () => {
        toast({
          title: 'Failed to send feedback. Please try again later.',
          status: 'error',
        })
      },
    },
  )
}

// For citizenFeedback, current intention is to not show any error and proceed as per normal
export const useSendCitizenFeedbackMutation = ({
  onSuccess,
}: {
  onSuccess?: () => void
} = {}) => {
  return useMutation(async (feedbackData: CitizenFeedbackDto) => {
    return api.url('/feedback/citizen').post(feedbackData).res()
  })
}
