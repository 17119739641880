import {
  Box,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BiCheckCircle, BiErrorCircle } from 'react-icons/bi'
import { WretchError } from 'wretch/resolver'

import { useToast } from '~/hooks/useToast'
import { Loading } from '~components/Loading'
import { useAdminUser } from '~features/auth/hooks/auth.hooks'
import {
  MESSAGE_TYPE,
  useAutoClearMessage,
} from '~features/template-library/hooks/message.hooks'
import {
  getToastMessage,
  ToastMessageType,
  useAddCollaboratorAccess,
  useGetTemplateCollaborators,
  useRemoveCollaboratorAccess,
} from '~features/template-library/hooks/templateLibrary.hooks'
import { SUPPORT_EMAIL } from '~shared/constants/letters'
import { isGovSgOrWhitelistedEmail } from '~shared/decorators/is-gov-sg-or-whitelisted-email'

import { CollaboratorRow } from '../CollaboratorRow'

interface ManageCollaboratorAccessModalProps {
  isOpen: boolean
  onClose: () => void
  templateId: number
  templateName: string | undefined
}

interface CollaboratorFormData {
  collaboratorEmail: string
}

export const ManageCollaboratorAccessModal = ({
  isOpen,
  onClose,
  templateId,
  templateName,
}: ManageCollaboratorAccessModalProps): JSX.Element => {
  const toast = useToast({
    position: 'top',
    isClosable: true,
    variant: 'subtle',
    containerStyle: {
      width: '500px',
      maxWidth: '100%',
    },
  })
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch,
  } = useForm<CollaboratorFormData>()

  const collaboratorEmail = watch('collaboratorEmail')

  const { message, setMessage, clearMessage } = useAutoClearMessage()

  const [error, setError] = useState<string | undefined>(undefined)

  const { collaborators, isDataLoading: isLoadingCollaborators } =
    useGetTemplateCollaborators(templateId)

  const toastIdRef = useRef<string | number>()

  const {
    mutateAsync: mutateAsyncRemoveAccess,
    isLoading: removeAccessIsLoading,
  } = useRemoveCollaboratorAccess({
    onSuccess: (_res, { userEmail }) => {
      const toastMessage = getToastMessage(
        ToastMessageType.COLLABORATOR_REMOVE_SUCCESS,
        userEmail,
      )
      if (toastMessage) {
        if (toastIdRef.current && toast.isActive(toastIdRef.current)) {
          toast.update(toastIdRef.current, toastMessage)
        } else {
          toastIdRef.current = toast(toastMessage)
        }
      }
    },
    onError: (err: WretchError) => {
      setMessage({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        text: err.json?.message as string,
        type: MESSAGE_TYPE.REMOVE_ERROR,
      })
    },
  })

  const { mutateAsync, isLoading } = useAddCollaboratorAccess({
    onSuccess: (_res, { userEmail }) => {
      const toastMessage = getToastMessage(
        ToastMessageType.COLLABORATOR_ADD_SUCCESS,
        userEmail,
      )
      if (toastMessage) {
        if (toastIdRef.current && toast.isActive(toastIdRef.current)) {
          toast.update(toastIdRef.current, toastMessage)
        } else {
          toastIdRef.current = toast(toastMessage)
        }
      }
      reset()
    },
    onError: (err: WretchError) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      setError(err.json?.message as string)
    },
  })
  useEffect(() => {
    if (error != undefined) {
      setError(undefined)
    }
    if (message != undefined) {
      clearMessage()
    }
  }, [collaboratorEmail])

  useEffect(() => {
    reset()
  }, [isOpen])

  const onSubmit = handleSubmit(async (data) => {
    await mutateAsync({
      templateId: templateId,
      userEmail: data.collaboratorEmail,
    })
  })

  const { adminUser } = useAdminUser()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {/* padding to offset close bottom = 44px (width of close button) + 32px (right padding) */}
        <ModalHeader textStyle={'h4'} pr="76px">
          {templateName !== undefined
            ? `Share "${templateName}"`
            : 'Manage collaborators'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack alignItems={'left'} spacing="0px" pt="10px">
            <VStack align={'left'} spacing="4px" mb="10px">
              <Text
                textStyle={'subhead-1'}
                fontSize={'16px'}
                textColor={'grey.500'}
              >
                Collaborators will have access to edit the template, issue
                letters using it and view all issued letters in their dashboard.
              </Text>
            </VStack>
            <form
              noValidate // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onSubmit={onSubmit}
            >
              <FormControl isInvalid={!!errors.collaboratorEmail || !!error}>
                <HStack mb="5px">
                  <InputGroup borderColor="grey.200">
                    <Input
                      type="email"
                      placeholder="Add people and group emails"
                      {...register('collaboratorEmail', {
                        validate: {
                          validOfficerEmail: (email) =>
                            email.length <= 1 ||
                            isGovSgOrWhitelistedEmail(email) === true ||
                            'Email should be a valid public officer email.',
                        },
                      })}
                      isDisabled={isLoading}
                    />
                  </InputGroup>

                  <Button // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={onSubmit}
                    isDisabled={isLoading}
                  >
                    Add
                  </Button>
                </HStack>
                <FormErrorMessage>
                  <HStack display={'flex'}>
                    <BiErrorCircle size={'20px'} />
                    <Text fontSize={'14px'}>
                      {errors.collaboratorEmail &&
                        errors.collaboratorEmail.message}
                      {error != undefined && error}
                    </Text>
                  </HStack>
                </FormErrorMessage>
                {isLoading && <Progress size="xs" isIndeterminate />}
              </FormControl>
            </form>
          </VStack>
          {isLoadingCollaborators ? (
            <Loading />
          ) : (
            <VStack alignItems={'left'} pb="40px" pt="20px">
              <Text textStyle={'h5'} mb="4px">
                People with access
              </Text>
              <Box maxHeight="200px" overflowY={'auto'}>
                <CollaboratorRow
                  userEmail={adminUser?.email ?? ''}
                  removeDisabled={true}
                />
                {collaborators?.map(
                  (collaborator, index) =>
                    // don't show our support email as a collaborator
                    // since support would always have access, but it might
                    // be confusing for our end users to see this email
                    // and they might accidentally end up removing it
                    collaborator !== SUPPORT_EMAIL &&
                    adminUser?.email !== collaborator && (
                      <CollaboratorRow
                        key={index}
                        userEmail={collaborator}
                        onClickRemove={() => {
                          // eslint-disable-next-line @typescript-eslint/no-floating-promises
                          mutateAsyncRemoveAccess({
                            userEmail: collaborator,
                            templateId: templateId,
                          })
                        }}
                      />
                    ),
                )}
              </Box>
              {message.type == MESSAGE_TYPE.REMOVE_SUCCESS && (
                <HStack mt="5px">
                  <BiCheckCircle color="green" size={'18px'} />
                  <Text fontSize={'14px'}> {message.text}</Text>
                </HStack>
              )}
              {removeAccessIsLoading && <Progress size="xs" isIndeterminate />}
              {message.type == MESSAGE_TYPE.REMOVE_ERROR && (
                <HStack
                  mt="5px"
                  display={'flex'}
                  textColor={'interaction.critical.default'}
                >
                  <BiErrorCircle size={'20px'} />
                  <Text fontSize={'14px'}>{message.text}</Text>
                </HStack>
              )}
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
