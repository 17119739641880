import { SimpleGrid } from '@chakra-ui/react'

import { CitizenNotificationMethod } from '~shared/dtos/letters.dto'
import { getLocaleTimeFromDate, parseDateV2 } from '~utils/dateUtils'
import {
  capitalizeFirstLetter,
  citizenNotificationMethodToString,
} from '~utils/stringUtils'

import { IssuedBatchViewDetailsComponent } from './IssuedBatchViewDetailsComponent'

export interface IssuedBatchViewDetailsProps {
  createdAt: string
  totalCount: number
  readCount: number
  notificationMethod?: CitizenNotificationMethod
}
export const IssuedBatchViewDetails = ({
  createdAt,
  totalCount,
  readCount,
  notificationMethod,
}: IssuedBatchViewDetailsProps): JSX.Element => {
  return (
    <SimpleGrid columns={4} spacing={4} w={'100%'}>
      <IssuedBatchViewDetailsComponent
        title="Sent On"
        primaryInformation={`${parseDateV2(createdAt, false)},`}
        secondaryInformation={` ${getLocaleTimeFromDate(createdAt)}`}
      />
      <IssuedBatchViewDetailsComponent
        title="Total Issued"
        primaryInformation={totalCount.toString()}
      />
      <IssuedBatchViewDetailsComponent
        title={`Letters Read (${((readCount / totalCount) * 100).toFixed(1)}%)`}
        primaryInformation={readCount.toString()}
        secondaryInformation={` / ${totalCount.toString()}`}
      />
      <IssuedBatchViewDetailsComponent
        title="Delivered by"
        primaryInformation={
          notificationMethod
            ? capitalizeFirstLetter(
                citizenNotificationMethodToString(notificationMethod),
              )
            : 'Other services'
        }
      />
    </SimpleGrid>
  )
}
