import {
  Flex,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

import ArchiveTemplateConfirmation from '~/assets/ArchiveTemplateConfirmation.svg'
import { useArchiveTemplate } from '~features/template-library/hooks/templateLibrary.hooks'

interface ConfirmArchiveModalProps {
  isOpen: boolean
  onClose: () => void
  templateId: number
}
export const ConfirmArchiveModal = ({
  isOpen,
  onClose,
  templateId,
}: ConfirmArchiveModalProps): JSX.Element => {
  const { mutateAsync, isLoading } = useArchiveTemplate({
    onSuccess: () => {
      onClose()
    },
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <Image
          alignSelf="center"
          src={ArchiveTemplateConfirmation}
          borderTopRadius={'8px'}
        />
        <VStack p="32px" alignItems={'left'}>
          <Text textStyle={'h4'} fontSize={'24px'}>
            Archiving this template will also archive it for all users who have
            access to it.
          </Text>
          <Text mb="1rem" textStyle={'subhead-1'}>
            You can unarchive it later if needed. Letters previously issued from
            this template will remain available in your dashboard.
          </Text>
        </VStack>
        <Flex marginLeft={'auto'} pb="32px" px="32px">
          <Button variant="outline" mr={3} onClick={onClose} border="0">
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={() => mutateAsync({ templateId })}
          >
            Confirm
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
