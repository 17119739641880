import { Box, Divider, HStack } from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

interface PaginationControlProps {
  canGoPrevious?: boolean
  canGoNext?: boolean
  goToPrevious?: (() => void) | null
  goToNext?: (() => void) | null
  nextButtonText?: string | null
}

export const PaginationControl = ({
  canGoPrevious = true,
  canGoNext,
  goToPrevious,
  goToNext,
  nextButtonText,
}: PaginationControlProps) => {
  return (
    <Box
      position="absolute"
      bg="standard.white"
      width="100%"
      bottom="0"
      left="0"
      h="92px"
      paddingX="40px"
    >
      <Divider margin={'0'} />
      <HStack
        paddingTop={'16px'}
        width="100%"
        display={'flex'}
        paddingBottom={'32px'}
      >
        {goToPrevious ? (
          <Button
            variant={'outline'}
            onClick={goToPrevious}
            isDisabled={!canGoPrevious}
            flex={1}
          >
            Previous
          </Button>
        ) : (
          <></>
        )}
        {goToNext ? (
          <Button flex={1} onClick={goToNext} isDisabled={!canGoNext}>
            {nextButtonText || 'Next step'}
          </Button>
        ) : (
          <></>
        )}
      </HStack>
    </Box>
  )
}
