import {
  IsDefined,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  Matches,
  Max,
  MaxLength,
  Min,
} from 'class-validator'

const ALLOWED_CHARACTERS = /^[a-zA-Z0-9!-_.*'()\n ]+$/

export enum FeedbackCategories {
  GENERAL = 'General',
  PUBLIC = 'Public',
}

export class FeedbackDto {
  @IsDefined()
  @IsEnum(FeedbackCategories)
  feedbackName: string
  @IsDefined()
  @IsNumber()
  @Min(1, { message: 'Rating must be at least 1' })
  @Max(5, { message: 'Rating cannot be greater than 5' })
  rating: number
  @IsOptional()
  @IsString()
  @Matches(ALLOWED_CHARACTERS)
  @MaxLength(500)
  feedbackText: string
}

export class CitizenFeedbackDto {
  @IsDefined()
  @IsString()
  letterPublicId: string
  @IsDefined()
  @IsString()
  citizenFeedbackTitle: string
  @IsDefined()
  @IsString()
  citizenFeedbackText: string
  @IsDefined()
  @IsString()
  citizenFeedbackFreeText: string
}
