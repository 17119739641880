import {
  Button,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'

import ExitTemplateEditor from '~/assets/ExitTemplateEditor.svg'

interface ExitEditorModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export const ExitEditorModal = ({
  isOpen,
  onClose,
  onConfirm,
}: ExitEditorModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton onClick={onClose} />
        <Image
          alignSelf="center"
          src={ExitTemplateEditor}
          borderTopRadius={'8px'}
        />
        <VStack p="32px" alignItems={'left'}>
          <Text textStyle={'h4'} fontSize={'24px'}>
            Your edits will not be saved.
          </Text>
          <Text mb="1rem" textStyle={'subhead-1'}>
            Are you sure you want to leave the editor?
          </Text>
        </VStack>
        <ModalFooter>
          <Button
            variant="ghost"
            onClick={onClose}
            border={0}
            colorScheme="blue"
            mr={3}
          >
            Cancel
          </Button>
          <Button onClick={onConfirm}>Confirm</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ExitEditorModal
