import {
  Button,
  HStack,
  Icon,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { isString } from 'class-validator'
import { BiArrowBack } from 'react-icons/bi'
import { useLocation, useNavigate } from 'react-router-dom'

import { useGetBatchPdfById } from '~features/dashboard/hooks/dashboard.hooks'
import { ManageCollaboratorAccessModal } from '~features/template-library/components/modals/ManageCollaboratorAccessModal'
import { GetBatchDto } from '~shared/dtos/batches.dto'
import { GrowthBookFeatures } from '~shared/types/feature-flag'

import { handleDownloadCsvReport } from '../DownloadCsvReportButton'
import { DownloadMenu } from '../DownloadMenu'

export interface IssuedBatchViewHeaderProps {
  batchDetails: GetBatchDto
}
export const IssuedBatchViewHeader = ({
  batchDetails,
}: IssuedBatchViewHeaderProps): JSX.Element => {
  const sampleTemplateId = useFeatureValue(
    GrowthBookFeatures.sampleTemplateId,
    0,
  )

  const { templateName, senderEmail, templateId } = batchDetails
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()
  const { getBatchPdfUrl } = useGetBatchPdfById()

  const location = useLocation()
  const isFromDashboard =
    isString(location.state) && location.state == 'isFromDashboard'

  return (
    <>
      <HStack width="100%" display="flex" justifyContent="space-between">
        <HStack spacing={3}>
          <Icon
            as={BiArrowBack}
            onClick={() =>
              isFromDashboard ? navigate(-1) : navigate('/admin/letters')
            }
            boxSize={5}
            aria-label="back-to-dashboard"
          />
          <VStack align={'start'} spacing={0}>
            <Text
              textStyle={'h4'}
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              maxWidth="50vw"
            >
              {templateName}
            </Text>
            {sampleTemplateId !== templateId && (
              <HStack spacing={2} mt={1}>
                <Text textStyle={'subhead-2'} color={'base.content.medium'}>
                  Issued by {senderEmail}
                </Text>
              </HStack>
            )}
          </VStack>
        </HStack>
        <HStack spacing={3}>
          {sampleTemplateId !== templateId && (
            <Button variant={'outline'} onClick={onOpen}>
              Share access
            </Button>
          )}
          <DownloadMenu
            onDownloadCsv={() => handleDownloadCsvReport(batchDetails)}
            onDownloadPdf={() => getBatchPdfUrl(batchDetails.batchId)}
          />
        </HStack>
      </HStack>
      <ManageCollaboratorAccessModal
        isOpen={isOpen}
        onClose={onClose}
        templateId={templateId}
        templateName={templateName}
      />
    </>
  )
}
