export const colors = {
  blue: {
    '50': '#F8F9FD',
    '100': '#E5E9F8',
    '200': '#B2BDEB',
    '300': '#8D9DE1',
    '400': '#697FD7',
    '500': '#445FCD',
    '600': '#344BA9',
    '700': '#2D4193',
    '800': '#26377E',
    '900': '#1D2A5E',
  },
  slate: {
    '50': '#F8F9FA',
    '100': '#E9EAEE',
    '200': '#BABECB',
    '300': '#9AA0B3',
    '400': '#7B849C',
    '500': '#5D6785',
    '600': '#465173',
    '700': '#3C4764',
    '800': '#333C56',
    '900': '#272D41',
  },
  brand: {
    primary: {
      '50': '#F8F9FD',
      '100': '#E5E9F8',
      '200': '#B2BDEB',
      '300': '#8D9DE1',
      '400': '#8D9DE1',
      '500': '#445FCD',
      '600': '#344BA9',
      '700': '#2D4193',
      '800': '#26377E',
      '900': '#1D2A5E',
    },
    secondary: {
      '50': '#F8F9FA',
      '100': '#E9EAEE',
      '200': '#BABECB',
      '300': '#9AA0B3',
      '400': '#7B849C',
      '500': '#5D6785',
      '600': '#465173',
      '700': '#3C4764',
      '800': '#333C56',
      '900': '#272D41',
    },
  },
  red: {
    '50': '#fef7f7',
    '100': '#FBE9E9',
    '200': '#f4acac',
    '300': '#ee8080',
    '400': '#e74f4f',
    '500': '#c03434',
    '600': '#992a2a',
    '700': '#852424',
    '800': '#721f1f',
    '900': '#571717',
  },
  green: {
    '50': '#F5FAFA',
    '100': '#E2EEED',
    '200': '#98CCC7',
    '300': '#63B1AA',
    '400': '#2D978C',
    '500': '#0F796F',
    '600': '#0C5F57',
    '700': '#0A524B',
    '800': '#09463F',
    '900': '#073430',
  },
  yellow: {
    '50': '#fffae1',
    '100': '#FFDA68',
    '200': '#E2B73E',
    '300': '#C4992A',
    '400': '#A77C18',
    '500': '#8B6005',
    '600': '#704C00',
    '700': '#624200',
    '800': '#533800',
    '900': '#3f2b00',
  },
  grey: {
    '50': '#F8F9F9',
    '100': '#EDEDED',
    '200': '#BFC2C8',
    '300': '#A0A4AD',
    '400': '#838894',
    '500': '#666C7A',
    '600': '#505660',
    '700': '#454953',
    '800': '#3A3E46',
    '900': '#2C2E34',
  },
  standard: {
    white: '#ffffff',
    black: '#000000',
  },
  skin: {
    '1base': '#FFCAA4',
    '1shadow': '#F5B896',
    '2base': '#EBA687',
    '2shadow': '#E29B7B',
    '3base': '#D98F6F',
    '3shadow': '#CE8361',
    '4base': '#BF7657',
    '4shadow': '#AF6D53',
    '5base': '#9F634F',
    '5shadow': '#8B5544',
    '6base': '#764738',
    '6shadow': '#633D32',
  },
  base: {
    canvas: {
      default: '#F8F9FD',
      alt: '#ffffff',
      backdrop: '#EDEDED',
      'brand-subtle': '#F8F9FD',
      inverse: '#3A3E46',
      overlay: 'rgba(58, 62, 70, 0.8)',
    },
    content: {
      default: '#454953',
      strong: '#2C2E34',
      medium: '#666C7A',
      brand: '#445FCD',
      inverse: '#ffffff',
    },
    divider: {
      subtle: '#F8F9F9',
      medium: '#EDEDED',
      strong: '#BFC2C8',
      inverse: '#ffffff',
      brand: '#445FCD',
    },
  },
  interaction: {
    main: {
      default: '#445FCD',
      hover: '#344BA9',
      active: '#2D4193',
    },
    'main-subtle': {
      default: '#E5E9F8',
      hover: '#B2BDEB',
      active: '#8D9DE1',
    },
    sub: {
      default: '#5D6785',
      hover: '#465173',
      active: '#3C4764',
    },
    'sub-subtle': {
      default: '#E9EAEE',
      hover: '#BABECB',
      active: '#9AA0B3',
    },
    critical: {
      default: '#c03434',
      hover: '#992a2a',
      active: '#852424',
    },
    'critical-subtle': {
      default: '#FBE9E9',
      hover: '#f4acac',
      active: '#ee8080',
    },
    warning: {
      default: '#FFDA68',
      hover: '#E2B73E',
      active: '#C4992A',
    },
    'warning-subtle': {
      default: '#fffae1',
      hover: '#FFDA68',
      active: '#E2B73E',
    },
    success: {
      default: '#0F796F',
      hover: '#0C5F57',
      active: '#0A524B',
    },
    'success-subtle': {
      default: '#E2EEED',
      hover: '#98CCC7',
      active: '#63B1AA',
    },
    neutral: {
      default: '#666C7A',
      hover: '#505660',
      active: '#454953',
    },
    'neutral-subtle': {
      default: '#F8F9F9',
      hover: '#EDEDED',
      active: '#BFC2C8',
    },
    muted: {
      main: {
        hover: '#F8F9FD',
        active: '#E5E9F8',
      },
      sub: {
        hover: '#F8F9FA',
        active: '#E9EAEE',
      },
      critical: {
        hover: '#fef7f7',
        active: '#FBE9E9',
      },
      neutral: {
        hover: '#F8F9F9',
        active: '#EDEDED',
      },
    },
    tinted: {
      main: {
        hover: 'rgba(68, 95, 205, 0.04)',
        active: 'rgba(68, 95, 205, 0.12)',
      },
      sub: {
        hover: 'rgba(93, 103, 133, 0.04)',
        active: 'rgba(93, 103, 133, 0.12)',
      },
      critical: {
        hover: 'rgba(192, 52, 52, 0.04)',
        active: 'rgba(192, 52, 52, 0.12)',
      },
      neutral: {
        hover: 'rgba(0, 0, 0, 0.1)',
        active: 'rgba(0, 0, 0, 0.2)',
      },
      inverse: {
        hover: 'rgba(255, 255, 255, 0.1)',
        active: 'rgba(255, 255, 255, 0.2)',
      },
    },
    support: {
      unselected: '#A0A4AD',
      selected: '#666C7A',
      'unselected-strong': '#666C7A',
      disabled: '#EDEDED',
      'disabled-content': '#A0A4AD',
      placeholder: '#A0A4AD',
    },
    links: {
      default: '#445FCD',
      hover: '#344BA9',
      'neutral-default': '#454953',
      'neutral-hover': '#2C2E34',
      'inverse-default': '#ffffff',
      'inverse-hover': '#EDEDED',
    },
  },
  utility: {
    feedback: {
      info: '#445FCD',
      'info-subtle': '#F8F9FD',
      warning: '#FFDA68',
      'warning-subtle': '#fffae1',
      success: '#0F796F',
      'success-subtle': '#F5FAFA',
      critical: '#c03434',
      'critical-subtle': '#fef7f7',
    },
    'focus-default': '#445FCD',
    'focus-inverse': '#ffffff',
    'input-prefilled': '#fffae1',
    ui: '#ffffff',
    'ui-clear': 'rgba(255, 255, 255, 0)',
  },
}
