import { Image, Text, VStack } from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

import EmptyTemplateLibrary from '~/assets/EmptyTemplateLibrary.svg'

interface EmptyTemplatesBodyProps {
  buttonText: string
  handleOnClick: () => void
}

export const EmptyTemplatesBody = ({
  buttonText,
  handleOnClick,
}: EmptyTemplatesBodyProps) => {
  return (
    <VStack
      minHeight="80vh"
      justifyContent="center"
      alignItems="center"
      spacing={8}
    >
      <Image maxW="100%" maxH="100%" src={EmptyTemplateLibrary} />
      <Text textStyle="h4" textColor="standard.black" align="center">
        No templates available yet
        <Text
          textStyle="body-1"
          textColor="base.content.default"
          mt="2"
          align="center"
        >
          Create a template to get started
        </Text>
      </Text>

      <Button bg="interaction.main.default" onClick={handleOnClick}>
        {buttonText}
      </Button>
    </VStack>
  )
}
