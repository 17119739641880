import { Box, FormControl } from '@chakra-ui/react'
import { SingleSelect } from '@opengovsg/design-system-react'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useGetTemplates } from '~features/dashboard/hooks/dashboard.hooks'
import { BulkIssueCardContainer } from '~features/issue/components/BulkIssueCardContainer'

import { BulkLetterIssueFormState } from '../../states/BulkLetterIssueFormState'

export const BulkIssueTemplateSelectionCard = () => {
  const navigate = useNavigate()
  const { watch, setValue } = useFormContext<BulkLetterIssueFormState>()
  const selectedTemplate = watch('selectedTemplate')
  const { templates, isTemplatesLoading } = useGetTemplates({
    limit: 10000, // max number of templates for now
    offset: 0,
  })

  useEffect(
    function redirectIfNoTemplates() {
      !isTemplatesLoading &&
        templates.length === 0 &&
        navigate('/admin/templates')
    },
    [templates, navigate, isTemplatesLoading],
  )

  return (
    <BulkIssueCardContainer>
      <FormControl>
        <Box mb="24px" textColor={'grey.900'}>
          <SingleSelect
            fixedItemHeight={80}
            name="selectedTemplate"
            onChange={(value) => {
              const selectedTemplate = templates.find(
                (template) => template.name === value,
              )
              setValue('selectedTemplate', selectedTemplate, {
                shouldDirty: true,
              })
            }}
            size="md"
            value={selectedTemplate?.name ?? ''}
            items={
              templates &&
              templates.map((template) => {
                return {
                  label: `${template.name}`,
                  value: `${template.name}`,
                }
              })
            }
          />
        </Box>
      </FormControl>
    </BulkIssueCardContainer>
  )
}
