import { Box, Button, Text } from '@chakra-ui/react'
import { BiDownload } from 'react-icons/bi'

import { GetBatchDto } from '~shared/dtos/batches.dto'
import { GetLetterDto } from '~shared/dtos/letters.dto'
import { NotificationStatus } from '~shared/dtos/notifications.dto'
import { jsonArrToCsv } from '~utils/csvUtils'
import { getLetterPublicLink } from '~utils/linkUtils'

export interface DownloadCsvReportButtonProps {
  batch: GetBatchDto
  showText?: boolean
  showIcon?: boolean
  size?: string
  variant: string
}

export const handleDownloadCsvReport = (batch: GetBatchDto) => {
  const { letters, templateName, createdAt } = batch

  const downloadCsvName = `${templateName} [COMPLETED].csv`

  if (!letters) return

  const bulkLettersWithLink = letters.map((letter: GetLetterDto) => {
    // convert field values string into JSON object
    const letterParams = JSON.parse(letter?.fieldValues ?? '{}') as {
      [key: string]: string
    }
    return {
      ...letterParams,
      ...(letter.recipient && { recipients: letter.recipient }),
      read_status: letter.firstReadAt
        ? letter.firstReadAt
        : letter.notificationStatus === NotificationStatus.FAIL ||
          letter.notificationStatus === NotificationStatus.INVALID_RECIPIENT
        ? 'Bounced'
        : 'Unread',
      letters_link: `https://${getLetterPublicLink(letter.publicId)}`,
      date_of_issue: createdAt,
    }
  })
  jsonArrToCsv(downloadCsvName, bulkLettersWithLink)
}

export const DownloadCsvReportButton = ({
  batch,
  showText = true,
  showIcon = true,
  variant,
}: DownloadCsvReportButtonProps): JSX.Element => {
  return (
    <Button
      variant={variant}
      onClick={(event) => {
        // this is to prevent clicks from propogating
        // to underlying components for e.g. batch table rows
        event.stopPropagation()
        handleDownloadCsvReport(batch)
      }}
    >
      {showIcon && <BiDownload size="1.5rem" />}

      {showText && (
        <>
          <Box paddingLeft="5px" />
          <Text
            overflow={'hidden'}
            textOverflow="ellipsis"
            whiteSpace={'nowrap'}
            color={variant === 'solid' ? 'standard.white' : 'brand.primary.600'}
          >
            Download CSV report
          </Text>
        </>
      )}
    </Button>
  )
}
