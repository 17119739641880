import { Button, HStack, Text, VStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import { routes } from '~constants/routes'

export const WelcomeCard = (): JSX.Element => {
  const navigate = useNavigate()

  return (
    <HStack
      width="100%"
      display="flex"
      justifyContent="space-between"
      paddingTop={'50px'}
      paddingBottom={'20px'}
    >
      <VStack align={'left'} spacing="30px">
        <VStack align={'left'}>
          <Text textStyle={'h3'}>Dashboard</Text>
          <Text>
            View and track the performance of your past letters in batches below
          </Text>
        </VStack>
      </VStack>
      <Button
        onClick={() =>
          navigate(
            `/${routes.admin.index}/${routes.admin.templates.index}/issue`,
          )
        }
      >
        Create letters
      </Button>
    </HStack>
  )
}
