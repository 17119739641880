import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  FormControl,
  HStack,
  ListItem,
  OrderedList,
  Radio,
  RadioGroup,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react'
import { Badge } from '@opengovsg/design-system-react'
import { useFormContext } from 'react-hook-form'

import { CitizenNotificationMethod } from '~shared/dtos/letters.dto'

import { BulkLetterIssueFormState } from '../../states/BulkLetterIssueFormState'
import { BulkIssueCardContainer } from '../BulkIssueCardContainer'
import { SendSampleForm } from '../SendSampleForm'

export const BulkIssueSettingsCard = () => {
  const { register, watch, setValue } =
    useFormContext<BulkLetterIssueFormState>()
  const citizenNotificationMethod = watch('citizenNotificationMethod')

  return (
    <BulkIssueCardContainer>
      <FormControl isInvalid={citizenNotificationMethod == null}>
        <Box mx={'2px'}>
          <RadioGroup
            {...(register('citizenNotificationMethod'),
            {
              onChange: (newVal: CitizenNotificationMethod) => {
                if (newVal) {
                  setValue('citizenNotificationMethod', newVal)
                }
              },
            })}
            value={citizenNotificationMethod}
          >
            <VStack spacing={6} align="left" pb={8}>
              <Box
                bg="#F7FAFF;"
                fontSize={'14px'}
                borderRadius={'4px'}
                px={6}
                py={4}
              >
                <Text>
                  <b>Note:</b> You can download your letters as PDFs from the
                  dashboard regardless of the option you select below.
                </Text>
              </Box>
              <VStack spacing={2} align="left">
                <HStack spacing={4}>
                  <Text
                    textStyle="subhead-3"
                    fontSize={'14px'}
                    color={'grey.300'}
                  >
                    Using LetterSG
                  </Text>
                  <Badge variant="subtle" borderRadius={'xl'}>
                    Recommended
                  </Badge>
                </HStack>
                <HStack spacing={1}>
                  <Radio value={CitizenNotificationMethod.EMAIL} size={'sm'}>
                    <HStack>
                      <Text textStyle="body-1">Email</Text>
                    </HStack>
                  </Radio>
                  <Radio value={CitizenNotificationMethod.SMS} size={'sm'}>
                    <Text textStyle="body-1">SMS</Text>
                  </Radio>
                </HStack>
                <Box>
                  <UnorderedList
                    fontSize={'14px'}
                    color={'interaction.neutral.default'}
                    spacing={3}
                    fontWeight={500}
                    pl={4}
                  >
                    <ListItem>
                      Send letters instantly using a single platform
                    </ListItem>
                    <ListItem>
                      No customisation of content in SMS/email templates
                    </ListItem>
                  </UnorderedList>
                </Box>
              </VStack>
              <SendSampleForm />
              <Divider />
              <VStack spacing={2} align="left">
                <Text textStyle="subhead-3" fontSize={'14px'} color="grey.300">
                  Using other applications
                </Text>
                <Radio value={CitizenNotificationMethod.NONE} size={'sm'}>
                  <Text textStyle="body-1">Other services</Text>
                </Radio>
                <Box>
                  <UnorderedList
                    fontSize={'14px'}
                    color={'interaction.neutral.default'}
                    fontWeight={500}
                    spacing={3}
                    pl={2}
                  >
                    <ListItem>
                      <Accordion borderColor={'transparent'} allowToggle>
                        <AccordionItem>
                          <Flex flexDirection="row">
                            <Text maxWidth="80%" mr={1}>
                              Send your letters using different platforms.
                            </Text>
                            <AccordionButton
                              padding={0}
                              width="10%"
                              fontSize={'14px'}
                              color={'interaction.neutral.default'}
                              fontWeight={500}
                              _hover={{ backgroundColor: 'transparent' }}
                            >
                              <Text color={'base.content.brand'}>How?</Text>
                              <AccordionIcon color={'base.content.brand'} />
                            </AccordionButton>
                          </Flex>
                          <AccordionPanel p={0} pt={2}>
                            <OrderedList pl={2} fontWeight={400} spacing={1}>
                              <ListItem>
                                Generate links for your letters on LetterSG
                              </ListItem>
                              <ListItem>
                                Send links out digitally via tools like
                                Postman/SMS Dome
                              </ListItem>
                            </OrderedList>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </ListItem>
                    <ListItem>
                      Customise content in SMS/email templates and schedule
                      sending of letters
                    </ListItem>
                  </UnorderedList>
                </Box>
              </VStack>
            </VStack>
          </RadioGroup>
        </Box>
      </FormControl>
    </BulkIssueCardContainer>
  )
}
