import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { adminQueryKeys } from '~constants/query-keys'
import { useToast } from '~hooks/useToast'
import { api } from '~lib/api'
import {
  ApiKeyResponseDto,
  ApiKeySuffixResponseDto,
} from '~shared/dtos/auth.dto'

export const useCreateApiKeyForUser = () => {
  const queryClient = useQueryClient()
  const toast = useToast()

  const { mutateAsync, isLoading } = useMutation(
    () => api.url('/auth/api-key').put().json<ApiKeyResponseDto>(),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([adminQueryKeys.apiKeySuffix])
        toast({
          description: `API key successfully generated!`,
          status: 'success',
          duration: 10000,
          isClosable: true,
        })
      },
      onError: () => {
        toast({
          description: `Error creating API key. Please contact us at support@letters.gov.sg if you need assistance.`,
          status: 'error',
          duration: 10000,
          isClosable: true,
        })
      },
    },
  )
  return { createApiKeyMutation: mutateAsync, apiKeyLoading: isLoading }
}

export const useGetApiKeySuffixForUser = () => {
  const { data, isLoading } = useQuery([adminQueryKeys.apiKeySuffix], () =>
    api.url(`/auth/api-key-suffix`).get().json<ApiKeySuffixResponseDto>(),
  )
  return { apiKeySuffix: data?.keySuffix, apiKeySuffixLoading: isLoading }
}
