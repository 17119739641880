import { useQuery } from '@tanstack/react-query'

import { adminQueryKeys } from '~constants/query-keys'
import { api } from '~lib/api'
import { TemplateVersionDto } from '~shared/dtos/templates.dto'

export const useGetTemplateHistory = (templateId: number) => {
  const { data, isLoading, error } = useQuery(
    adminQueryKeys.history(templateId),
    () => {
      return api
        .url(`/templates/${templateId}/history`)
        .get()
        .json<TemplateVersionDto[]>()
    },
    { retry: false },
  )

  return { templateHistory: data, isTemplatesHistoryLoading: isLoading, error }
}
