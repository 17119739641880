import { UseToastOptions } from '@chakra-ui/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { WretchError } from 'wretch/resolver'

import { adminQueryKeys } from '~constants/query-keys'
import { useToast } from '~hooks/useToast'
import { api } from '~lib/api'
import { AccessDto } from '~shared/dtos/access.dto'

export enum ToastMessageType {
  COLLABORATOR_ADD_SUCCESS = 'collaborator_add_success',
  COLLABORATOR_REMOVE_SUCCESS = 'remove_collaborator_success',
}

export const getToastMessage = (
  messageType: ToastMessageType,
  toastInfo: string,
): UseToastOptions | null => {
  switch (messageType) {
    case ToastMessageType.COLLABORATOR_ADD_SUCCESS:
      return {
        status: 'success',
        description: `Successfully added ${toastInfo}`,
      }
    case ToastMessageType.COLLABORATOR_REMOVE_SUCCESS:
      return {
        status: 'success',
        description: `Successfully removed ${toastInfo}`,
      }
  }
  return null
}

export const useGetTemplateCollaborators = (templateId: number) => {
  const { data, isLoading, error } = useQuery(
    adminQueryKeys.collaborators(templateId),
    () => {
      return api
        .url(`/templates/${templateId}/collaborators`)
        .get()
        .json<string[]>()
    },
  )

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return { collaborators: data!, isDataLoading: isLoading, error }
}

export const useAddCollaboratorAccess = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: [], accessDto: AccessDto) => void
  onError?: (errors: WretchError, accessDto: AccessDto) => void
} = {}) => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body: AccessDto): Promise<[]> => {
      return await api.url(`/templates/invite`).post(body).json<[]>()
    },
    {
      onSuccess: async (res: [], accessDto: AccessDto) => {
        // invalidate letters dashboard queries
        await queryClient.invalidateQueries(adminQueryKeys.collaborators())
        onSuccess?.(res, accessDto)
      },
      onError: (error: WretchError, accessDto: AccessDto) => {
        onError?.(error, accessDto)
      },
    },
  )
}

export const useRemoveCollaboratorAccess = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: [], accessDto: AccessDto) => void
  onError?: (errors: WretchError, accessDto: AccessDto) => void
} = {}) => {
  const queryClient = useQueryClient()
  return useMutation(
    async (body: AccessDto): Promise<[]> => {
      return await api.url(`/templates/remove`).post(body).json<[]>()
    },
    {
      onSuccess: async (res: [], accessDto: AccessDto) => {
        // invalidate collaborators fetch queries
        await queryClient.invalidateQueries(adminQueryKeys.collaborators())
        onSuccess?.(res, accessDto)
      },
      onError: (error: WretchError, accessDto: AccessDto) => {
        onError?.(error, accessDto)
      },
    },
  )
}

export const useArchiveTemplate = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void
  onError?: (errors: WretchError) => void
} = {}) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation(
    async ({ templateId }: { templateId: number }): Promise<void> => {
      await api.url(`/templates/${templateId}`).delete().res()
    },
    {
      onSuccess: async () => {
        onSuccess?.()
        toast({
          description: `Template archived`,
          status: 'success',
          isClosable: true,
        })
        // invalidate the templates cache
        await queryClient.invalidateQueries(adminQueryKeys.templates())

        // Force a refetch of the templates
        await queryClient.refetchQueries(adminQueryKeys.templates(), {
          exact: false,
        })
      },
      onError: (error: WretchError) => {
        toast({
          description: 'Failed to archive template',
          status: 'error',
        })
        onError?.(error)
      },
    },
  )
}

export const useRestoreTemplate = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void
  onError?: (errors: WretchError) => void
} = {}) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  return useMutation(
    async ({ templateId }: { templateId: number }): Promise<void> => {
      await api.url(`/templates/${templateId}/recover`).put().res()
    },
    {
      onSuccess: async () => {
        onSuccess?.()
        toast({
          description: `Template restored. You can now see your template under Template Library.`,
          status: 'success',
          isClosable: true,
        })

        // invalidate the templates cache
        await queryClient.invalidateQueries(adminQueryKeys.templates())

        // Force a refetch of the templates
        await queryClient.refetchQueries(adminQueryKeys.templates(), {
          exact: false,
        })
      },
      onError: (error: WretchError) => {
        toast({
          description: `Failed to restore template`,
          status: 'error',
        })
        onError?.(error)
      },
    },
  )
}
