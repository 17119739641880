import { VStack } from '@chakra-ui/react'

import { Loading } from '~components/Loading'

import { IssuedBatchesTable, PAGE_SIZE } from './components/IssuedBatchesTable'
import { AnnnouncementModal } from './components/modals/AnnouncementModal'
import { NewUserState } from './components/NewUserState'
import { WelcomeCard } from './components/WelcomeCard'
import { useGetBatches } from './hooks/dashboard.hooks'

export const DashboardPage = (): JSX.Element => {
  const { count, isBatchesLoading } = useGetBatches(PAGE_SIZE, 0)

  return (
    <VStack spacing={0}>
      {isBatchesLoading ? (
        <Loading fullscreen={true} />
      ) : count === 0 ? ( // new user, no batches
        <NewUserState />
      ) : (
        <>
          <WelcomeCard />
          <IssuedBatchesTable />
        </>
      )}
      <AnnnouncementModal />
    </VStack>
  )
}
