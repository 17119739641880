import { extendTheme } from '@chakra-ui/react'
import { theme as ogpTheme } from '@opengovsg/design-system-react'

import { colors } from './colors'
import { components } from './components'
import { shadows } from './shadows'
import { sizes } from './sizes'
import { spacing } from './spacing'
import { textStyles } from './textStyles'
import { typography } from './typography'

/**
 * Design system themes can be found at
 * https://github.com/opengovsg/design-system/tree/main/token-gen/themes.
 * README for importing themes can be found at
 * https://github.com/opengovsg/design-system/tree/main/token-gen.
 */
export const theme = extendTheme(ogpTheme, {
  sizes: sizes,
  colors: colors,
  backgroundColors: colors,
  space: spacing,
  shadows: shadows,
  fontSizes: typography.fontSize,
  fontWeights: typography.fontWeights,
  lineHeights: typography.lineHeights,
  letterSpacings: typography.letterSpacing,
  borderColors: colors,
  textStyles,
  components,
})
